import { useQuery, gql } from "@hooks/useApollo";
import { BoxContainer } from "@atoms/BoxContainer";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { usePriceContext } from "@contexts/price";
import { useWeb3Context } from "@contexts/web3";
import { useMedia } from "@hooks/useMedia";
import { Select } from "@molecules/Select";
import { Statistic } from "@molecules/Statistic";
import { getTradePNL } from "@screens/Trade/utils";
import { formatLargeNumber, formatPrice } from "@utils";
import { Trade, User } from "gql/graphql";
import { memo, useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";
import { GET_USER_TRADES } from "../components/Overview";
import { useBalance } from "@hooks/useBalance";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";

export const PortfolioStatistics = memo(() => {
    const theme = useTheme();
    const media = useMedia();
    const { address } = useOstiumAccount();
    const { settings } = useAppContext();
    const { currentChain } = useWeb3Context();

    const { balance, blockNumber } = useBalance({
        address,
        watch: true,
    });

    const { initial } = useAppContext();

    const [user, setUser] = useState<User>(initial?.user as User);

    const { prices } = usePriceContext();

    const options = [
        {
            text: "All",
            value: "all",
        },
        {
            text: "Open",
            value: "open",
        },
        {
            text: "Closed",
            value: "closed",
        },
    ];

    const [selected, setSelected] = useState(options[0]);

    useQuery(GET_USER, {
        variables: {
            id: address?.toLowerCase(),
        },
        pollInterval: 10000,
        skip: !address,
        onCompleted: (data) => {
            setUser(data?.user);
        },
    });

    const { data: tradesData } = useQuery(GET_USER_TRADES, {
        variables: {
            trader: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 10000,
    });

    const balanceDAI = useMemo(() => {
        if (address)
            return `${formatPrice(Number(balance?.formatted) || 0, {
                currency: false,
                decimals: 2,
            })} ${currentChain.symbol}`;
        return 0;
    }, [balance?.formatted, address, currentChain?.symbol]);

    const userVolume = useMemo(() => {
        if (!!!user) return 0;
        switch (selected.value) {
            case "all":
                return formatLargeNumber(
                    Number(formatUnits(user?.totalVolume, 6))
                );
            case "open":
                return formatLargeNumber(
                    Number(formatUnits(user?.totalOpenVolume, 6))
                );
            case "closed":
                return formatLargeNumber(
                    Number(formatUnits(user?.totalClosedVolume, 6))
                );
            default: {
                return 0;
            }
        }
    }, [selected.value, user]);

    const pnl = useMemo(() => {
        if (selected.value === "closed")
            return user?.totalPnL ? Number(formatUnits(user?.totalPnL, 6)) : 0;
        return tradesData?.trades?.reduce(
            (acc: number, trade: Trade) => {
                const marketPrice = prices?.get(trade.pair.from);
                const data = getTradePNL(trade, marketPrice, blockNumber);

                return acc + data.netPNL;
            },
            selected.value === "all"
                ? user?.totalPnL
                    ? Number(formatUnits(user?.totalPnL, 6))
                    : 0
                : 0
        );
    }, [
        blockNumber,
        prices,
        selected.value,
        tradesData?.trades,
        user?.totalPnL,
    ]);

    const gain = useMemo(() => {
        if (selected.value === "closed")
            return user?.totalProfitTrades
                ? Number(user?.totalProfitTrades)
                : 0;

        return tradesData?.trades?.reduce(
            (acc: number, trade: Trade) => {
                const marketPrice = prices?.get(trade.pair.from);
                const data = getTradePNL(trade, marketPrice, blockNumber);

                if (data.netPNL > 0) return acc + 1;
                return acc;
            },
            selected.value === "all"
                ? user?.totalProfitTrades
                    ? Number(user?.totalProfitTrades)
                    : 0
                : 0
        );
    }, [
        blockNumber,
        prices,
        selected.value,
        tradesData?.trades,
        user?.totalProfitTrades,
    ]);

    const loss = useMemo(() => {
        if (selected.value === "closed")
            return user?.totalLossTrades ? Number(user?.totalLossTrades) : 0;
        return tradesData?.trades?.reduce(
            (acc: number, trade: Trade) => {
                const marketPrice = prices?.get(trade.pair.from);
                const data = getTradePNL(trade, marketPrice, blockNumber);

                if (data.netPNL < 0) return Number(acc) + 1;
                return acc;
            },
            selected.value === "all"
                ? Number(user?.totalLossTrades)
                    ? Number(user?.totalLossTrades)
                    : 0
                : 0
        );
    }, [
        blockNumber,
        prices,
        selected.value,
        tradesData?.trades,
        user?.totalLossTrades,
    ]);

    const protocolDailyTraders = useMemo(() => {
        return formatPrice(0, { currency: false });
    }, []);

    const protocolTraders = useMemo(() => {
        return formatPrice(0, { currency: false });
    }, []);

    const protocolVolume = useMemo(() => {
        return formatLargeNumber(0);
    }, []);

    const protocolDailyVolume = useMemo(() => {
        return formatLargeNumber(0);
    }, []);

    return (
        <Flex
            direction={media.isMobile ? "column" : "row"}
            gap={settings.theme.boxed ? theme.spacing.smaller : 0}
            style={{
                borderBottomWidth: settings.theme.boxed ? 0 : 1,
                borderColor: theme.color.rgba(theme.color.white, 0.05),
            }}
        >
            <BoxContainer
                style={{
                    flex: 1,
                    borderWidth: 0,
                    borderRightWidth: settings.theme.boxed ? 0 : 1,
                }}
            >
                <Box
                    style={{
                        padding: theme.spacing.bigger,
                    }}
                    gap={theme.spacing.small}
                >
                    <Flex
                        align="center"
                        justify="space-between"
                        style={{ zIndex: 1, position: "relative" }}
                    >
                        <Text
                            uppercase
                            small
                            color={theme.color.rgba(theme.color.white, 0.6)}
                        >
                            My statistics
                        </Text>
                        <Select
                            options={options}
                            selected={selected}
                            onChange={setSelected}
                        />
                    </Flex>
                    <Flex gap={theme.spacing.big}>
                        {/* <Statistic
                            label="Available Balance"
                            value={balanceDAI}
                            style={{
                                borderRightWidth: 1,
                                borderColor: theme.color.rgba(theme.color.white, 0.05),
                                paddingRight: theme.spacing.bigger,
                            }}
                        /> */}
                        <Statistic
                            label="Profit & Loss"
                            value={formatPrice(pnl, {
                                currency: true,
                                significant: 6,
                            })}
                            green={pnl > 0}
                            red={pnl < 0}
                            style={{
                                borderRightWidth: 1,
                                borderColor: theme.color.rgba(
                                    theme.color.white,
                                    0.05
                                ),
                                paddingRight: theme.spacing.bigger,
                            }}
                        />
                        <Statistic
                            label="Total Volume"
                            value={userVolume}
                            style={{
                                borderRightWidth: 1,
                                borderColor: theme.color.rgba(
                                    theme.color.white,
                                    0.05
                                ),
                                paddingRight: theme.spacing.bigger,
                            }}
                        />
                        <Statistic label="Gain vs Loss">
                            <Flex>
                                <Text small green bold>
                                    {gain}
                                </Text>
                                <Text small mono bold>
                                    {` - `}
                                </Text>
                                <Text small bold red>
                                    {loss}
                                </Text>
                            </Flex>
                        </Statistic>
                    </Flex>
                </Box>
            </BoxContainer>

            {/* <BoxContainer style={{ flex: 1, borderWidth: 0 }}>
                <Box
                    style={{
                        padding: theme.spacing.bigger,
                    }}
                    gap={theme.spacing.bigger}
                >
                    <Text uppercase small color={rgba(theme.color.white, 0.6)}>
                        Protocol statistics
                    </Text>
                    <Flex gap={theme.spacing.big}>
                        <Statistic
                            label="Total volume"
                            value={protocolVolume}
                            style={{
                                borderRightWidth: 1,
                                borderColor: rgba(theme.color.white, 0.05),
                                paddingRight: theme.spacing.bigger,
                            }}
                        />
                        <Statistic
                            label="Daily volume"
                            value={protocolDailyVolume}
                            style={{
                                borderRightWidth: 1,
                                borderColor: rgba(theme.color.white, 0.05),
                                paddingRight: theme.spacing.bigger,
                            }}
                        />
                        <Statistic
                            label="Traders"
                            value={protocolTraders}
                            style={{
                                borderRightWidth: 1,
                                borderColor: rgba(theme.color.white, 0.05),
                                paddingRight: theme.spacing.bigger,
                            }}
                        />
                        <Statistic
                            label="Daily Traders"
                            value={protocolDailyTraders}
                        />
                    </Flex>
                </Box>
            </BoxContainer> */}
        </Flex>
    );
});

export const GET_USER = gql`
    query getUser($id: ID!) @api(name: subgraph) {
        user(id: $id) {
            id
            totalVolume
            totalOpenVolume
            totalClosedVolume
            totalOpenTrades
            totalTrades
            totalMarketOrders
            totalOpenLimitOrders
            totalCancelledOrders
            totalProfitTrades
            totalLossTrades
            totalPnL
            totalTPOrders
            totalSLOrders
            totalLIQOrders
        }
    }
`;
