import { useAppContext } from "@contexts/app";
import { useNotificationContext } from "@contexts/notification";
import { usePriceContext } from "@contexts/price";
import { useSmartWalletContext } from "@contexts/smartWallet";
import { useAllowance } from "@contexts/hooks/useAllowance";
import { useWeb3Context } from "@contexts/web3";
import { useStoredState } from "@hooks/useStoredState";
import { CurrencyInputRef } from "@molecules/Input";
import { IMark } from "@molecules/Slider";
import {
    GetPriceImpact,
    GetTradeLiquidationPrice,
    PRECISION_12,
    PRECISION_18,
    PRECISION_2,
    WithinExposureLimit,
} from "@ostium/formulae/src";
import { useTradeContext } from "@screens/Trade/context";
import {
    Fees,
    ORDER_TYPES,
    POSITION_TYPES,
    PositionOrderTypes,
    PositionTypes,
    getFees,
} from "@screens/Trade/utils";
import { captureException } from "@sentry/nextjs";
import { BigNumber, capitalizeFirstLetter, formatPrice } from "@utils";
import { Pair } from "gql/graphql";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { parseWeb3Error } from "utils";
import {
    createPublicClient,
    encodeFunctionData,
    erc20Abi,
    formatUnits,
    getAddress,
    http,
    parseAbi,
    parseUnits,
} from "viem";
import { useChainId } from "wagmi";
import { useTakeProfitStopLoss } from "./TakeProfitStopLoss/useTakeProfitStopLoss";
import useAnalytics from "@hooks/useAnalytics";
import { TRADE_EVENT_NAMES } from "constants/events";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { OstiumTrading__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumTrading__factory";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react";

type RawTrade = {
    pairIndex: number;
    index: number;
    trader: string;
    collateral: bigint;
    openPrice: bigint;
    buy: boolean;
    leverage: bigint;
    tp: number | bigint;
    sl: number | bigint;
};

const TradeFormContext = createContext(
    {} as {
        isLoading?: boolean;
        pair?: Pair;
        pairs: Map<string, Pair>;
        price: number;
        allowance: number;

        orderPriceRef: React.MutableRefObject<CurrencyInputRef | null>;
        collateralRef: React.MutableRefObject<CurrencyInputRef | null>;
        stopLossInputRef: React.MutableRefObject<CurrencyInputRef | null>;
        takeProfitInputRef: React.MutableRefObject<CurrencyInputRef | null>;
        collateralInputRef: React.MutableRefObject<CurrencyInputRef | null>;
        positionSizeInputRef: React.MutableRefObject<CurrencyInputRef | null>;

        isWithinExposureLimit: boolean;
        isConfirmModalVisible: boolean;
        collateral: number;
        collateralAtOpen: number;
        exposure: number;
        positionSize: number;
        executionPrice: number;
        stopLoss: number;
        stopLossPercent: number;
        selectedTakeProfit: IMark | null;
        takeProfit: number;
        takeProfitPercent: number;
        selectedStopLoss: IMark | null;
        maxStopLoss: number;
        minStopLoss: number;
        maxTakeProfit: number;
        minTakeProfit: number;
        leverage: number;
        type: PositionTypes;
        orderType: PositionOrderTypes;
        orderPrice: number;
        liquidationPrice: number;
        maxCollateral: number;
        marketPrice?: {
            mid: number;
            bid: number;
            ask: number;
            isMarketOpen: boolean;
            timestampSeconds: number;
        } | null;
        minimumPositionSize: number;
        maxTakeProfitPercent: number;
        fees: Fees;
        maxLeverage: number;
        minLeverage: number;
        priceRaw: string;
        isPositionSizeSet: boolean;
        takeProfitLabel: string;
        takeProfitMarks: IMark[];
        stopLossLabel: string;
        isStopLossValid?: {
            valid: boolean;
            label?: string;
        };
        isTakeProfitValid?: {
            valid: boolean;
            label?: string;
        };
        onChangeSymbol: (pair: string) => void;
        onChangeType: (type: PositionTypes) => void;
        onChangeOrderType: (type: PositionOrderTypes) => void;
        onChangeOrderPrice: (price: number) => void;
        onChangeCollateral: (value: number) => void;
        onChangePositionSize: (value: number) => void;
        onChangeLeverage: (value: number | null) => void;
        onChangeLeverageMark: (mark: IMark) => void;
        onChangeStopLoss: (value: number) => void;
        onChangeTakeProfit: (value: number) => void;
        onChangeStopLossMark: (mark: IMark) => void;
        onChangeTakeProfitMark: (mark: IMark) => void;
        onBlurTakeProfit: (value: number) => void;
        onBlurStopLoss: (value: number) => void;
        onTogglePositionSizeSet: () => void;
        onTrade: () => Promise<void>;
        onSubmit: () => Promise<void>;
        onCloseConfirmModal: () => void;
        createOrder: () => Promise<void>;
    }
);

export const FORM_LOCAL_STORAGE_KEYS = {
    POSITION_TYPE: "POSITION_TYPE",
    ORDER_TYPE: "ORDER_TYPE",
    ORDER_PRICE: "ORDER_PRICE",
    COLLATERAL: "COLLATERAL",
    POSITION_SIZE: "POSITION_SIZE",
    LEVERAGE: "LEVERAGE",
    IS_POSITION_SIZE_SET: "IS_POSITION_SIZE_SET",
};

function useForm() {
    const { pair, changePair, isTradingDisabled } = useTradeContext();
    const { vaultContract, tradingContract, currentChain, provider } =
        useWeb3Context();
    const { trackEvent } = useAnalytics();
    const { settings, pairs, initial, isSmartAccountEnabled } = useAppContext();
    const { notify } = useNotificationContext();

    const { sendSmartWalletOrder, smartAccountClient, prepareUserOperation } =
        usePrivySmartWalletContext();
    const { prices } = usePriceContext();

    const { checkAllowance, allowance, updateAllowance, getAllowance } =
        useAllowance(currentChain.contracts.storage);
    const chainId = useChainId();
    const { address } = useOstiumAccount();

    const [type, setType] = useStoredState<PositionTypes>(
        POSITION_TYPES[0],
        FORM_LOCAL_STORAGE_KEYS.POSITION_TYPE
    );
    const [orderType, setOrderType] = useStoredState(
        ORDER_TYPES[0],
        FORM_LOCAL_STORAGE_KEYS.ORDER_TYPE
    );
    const [isLoading, setIsLoading] = useState(false);
    const [orderPrice, setOrderPrice] = useStoredState(
        0,
        FORM_LOCAL_STORAGE_KEYS.ORDER_PRICE,
        0
    );
    const [collateral, setCollateral] = useStoredState(
        100,
        FORM_LOCAL_STORAGE_KEYS.COLLATERAL,
        100
    );
    const [positionSize, setPositionSize] = useStoredState(
        0,
        FORM_LOCAL_STORAGE_KEYS.POSITION_SIZE,
        0
    );
    const [leverage, setLeverage] = useStoredState(
        10,
        FORM_LOCAL_STORAGE_KEYS.LEVERAGE,
        10
    );
    const [isPositionSizeSet, setIsPositionSizeSet] = useStoredState(
        false,
        FORM_LOCAL_STORAGE_KEYS.IS_POSITION_SIZE_SET,
        false
    );

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [vaultBalance, setVaultBalance] = useState(0);

    const orderPriceRef = useRef<CurrencyInputRef>(null);
    const collateralInputRef = useRef<CurrencyInputRef>(null);
    const positionSizeInputRef = useRef<CurrencyInputRef>(null);
    const collateralRef = useRef<CurrencyInputRef>(null);

    const marketPrice = useMemo(() => {
        if (pair?.from) return prices?.get(pair?.from);
        return initial.prices?.find((p) => p.from === pair?.from);
    }, [initial.prices, pair?.from, prices]);

    const price = useMemo(() => {
        if (orderType === PositionOrderTypes.Market && marketPrice?.mid)
            return marketPrice.mid;
        return orderPrice;
    }, [marketPrice?.mid, orderPrice, orderType]);

    const priceRaw = parseUnits(
        price ? price?.toFixed(10).toString() : "1",
        18
    ).toString();

    const fees = useMemo(
        () =>
            getFees({
                collateral,
                leverage,
                pair,
                price,
                type,
            }),
        [collateral, leverage, pair, price, type]
    );

    const minLeverage = useMemo(
        () =>
            pair?.group?.minLeverage
                ? Number(formatUnits(pair?.group?.minLeverage, 2))
                : 1,
        [pair]
    );
    const maxLeverage = useMemo(() => {
        let maxLev =
            pair?.maxLeverage && Number(formatUnits(pair?.maxLeverage, 2))
                ? pair?.maxLeverage
                : pair?.group?.maxLeverage;

        return maxLev ? Number(formatUnits(maxLev, 2)) : 1;
    }, [pair]);

    const maxCollateral: number = useMemo(() => {
        if (vaultBalance && pair?.group?.maxCollateralP) {
            return Number(
                formatUnits(
                    BigNumber.from(parseUnits(vaultBalance.toString(), 6))
                        .mul(pair?.group?.maxCollateralP)
                        .div(100)
                        .div(PRECISION_2)
                        .toBigInt(),
                    6
                )
            );
        }

        return 1000000;
    }, [pair?.group?.maxCollateralP, vaultBalance]);

    const minimumPositionSize = useMemo(() => {
        if (pair?.fee?.minLevPos)
            return Number(formatUnits(pair?.fee?.minLevPos, 6));
        return 0;
    }, [pair]);

    const exposure = useMemo(() => {
        if (collateral != null && collateral && leverage && fees?.value)
            return (collateral - fees.value) * leverage;
        return 100000;
    }, [collateral, leverage, fees.value]);

    const collateralAtOpen = useMemo(() => {
        return collateral - fees.value;
    }, [fees.value, collateral]);

    const executionPrice = useMemo(() => {
        if (!marketPrice?.mid || !marketPrice?.bid || !marketPrice?.ask)
            return 0;

        const notional = BigNumber.from(parseUnits(collateral.toString(), 6))
            .mul(parseUnits(leverage.toString(), 2))
            .div(PRECISION_2)
            .toString();

        return Number(
            formatUnits(
                BigInt(
                    GetPriceImpact(
                        parseUnits(marketPrice?.mid.toString(), 18).toString(),
                        parseUnits(marketPrice?.bid.toString(), 18).toString(),
                        parseUnits(marketPrice?.ask.toString(), 18).toString(),
                        pair?.spreadP,
                        true,
                        type === PositionTypes.Long,
                        true,
                        notional,
                        pair?.tradeSizeRef
                    )?.priceAfterImpact
                ),
                18
            )
        );
    }, [
        collateral,
        leverage,
        marketPrice?.ask,
        marketPrice?.bid,
        marketPrice?.mid,
        pair?.spreadP,
        pair?.tradeSizeRef,
        type,
    ]);

    const liquidationPrice = useMemo(() => {
        let newLiquidationPrice = 0;
        if (!price) return 0;
        if (
            Number(priceRaw) &&
            Number(collateral) &&
            isFinite(collateral) &&
            Number(leverage)
        ) {
            newLiquidationPrice = Number(
                formatUnits(
                    BigInt(
                        GetTradeLiquidationPrice(
                            priceRaw,
                            type === PositionTypes.Long,
                            parseUnits(collateral?.toString(), 6).toString(),
                            parseUnits(leverage.toString(), 2).toString(),
                            "0",
                            "0"
                        )
                    ),
                    18
                )
            );

            if (newLiquidationPrice <= 0) return 0;
        }
        return newLiquidationPrice;
    }, [price, priceRaw, collateral, leverage, type]);

    const currentBalance = (
        accPnlPerTokenUsed: number,
        totalSupply: number,
        accRewardsPerToken: number
    ): string => {
        const maxAccPnlPerToken = PRECISION_18.add(accRewardsPerToken);
        const result = maxAccPnlPerToken
            .sub(accPnlPerTokenUsed)
            .mul(totalSupply)
            .div(PRECISION_18);
        return result.toString();
    };

    const { data: vaultData, error: vaultError } = useQuery(GET_VAULT_BALANCE, {
        pollInterval: 1000,
    });

    const getVaultBalance = useCallback(() => {
        if (vaultData) {
            const { vault } = vaultData;
            const accPnlPerTokenUsed = vault.accPnlPerTokenUsed;
            const accRewardsPerToken = vault.rewardsPerToken;
            const totalSupply = vault.shares;
            const result = currentBalance(
                accPnlPerTokenUsed,
                totalSupply,
                accRewardsPerToken
            );

            if (result) {
                setVaultBalance(Number(formatUnits(BigInt(result), 6)));
            }
        } else {
            if (vaultError) {
                console.log("Vault error:", { vaultError });
            }
        }
    }, [vaultData, vaultError]);

    const getVaultBalanceFromContract = useCallback(async () => {
        if (currentChain.id === chainId)
            try {
                const result = await vaultContract?.currentBalance();
                if (result) {
                    //@ts-ignore
                    setVaultBalance(Number(formatUnits(result.toString(), 6)));
                }
            } catch (err) {
                console.warn(err);
            }
    }, [chainId, currentChain.id, vaultContract]);

    useEffect(() => {
        getVaultBalance();
        const interval = setInterval(() => {
            getVaultBalance();
        }, 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [getVaultBalance, vaultData]);

    const isWithinExposureLimit = useMemo(() => {
        if (!!!pair || !vaultBalance) return false;
        const oiLong = BigNumber.from(pair?.longOI)
            .mul(BigNumber.from(parseUnits(price.toString(), 18)))
            .div(BigNumber.from(PRECISION_18))
            .div(PRECISION_12);

        const oiShort = BigNumber.from(pair?.shortOI)
            .mul(BigNumber.from(parseUnits(price.toString(), 18)))
            .div(BigNumber.from(PRECISION_18))
            .div(PRECISION_12);

        const isWithinLimit = WithinExposureLimit(
            type === PositionTypes.Long
                ? oiLong.toString()
                : oiShort.toString(),
            pair?.maxOI,
            type === PositionTypes.Long
                ? pair?.group.longCollateral
                : pair?.group.shortCollateral,
            pair?.group.maxCollateralP,
            parseUnits(vaultBalance.toString(), 6).toString(),
            parseUnits(collateral.toFixed(6), 6).toString(),
            parseUnits(leverage.toString(), 2).toString()
        );

        return isWithinLimit;
    }, [pair, vaultBalance, price, type, collateral, leverage]);

    const {
        stopLossPercent,
        takeProfitPercent,
        selectedStopLoss,
        selectedTakeProfit,
        stopLoss,
        takeProfit,
        takeProfitInputRef,
        stopLossInputRef,
        maxStopLoss,
        maxTakeProfit,
        minStopLoss,
        minTakeProfit,
        takeProfitLabel,
        stopLossLabel,
        maxTakeProfitPercent,
        takeProfitMarks,
        isStopLossValid,
        isTakeProfitValid,
        onChangeStopLoss,
        onChangeTakeProfit,
        onChangeStopLossMark,
        onChangeTakeProfitMark,
        onBlurStopLoss,
        onBlurTakeProfit,
    } = useTakeProfitStopLoss({
        currentStopLoss: 0,
        currentTakeProfit: 0,
        isBuy: PositionTypes.Long === type,
        price: priceRaw,
        openPrice: priceRaw,
        leverage: leverage || minLeverage,
        pair,
    });

    useEffect(() => {
        setOrderPrice(0);
        orderPriceRef?.current?.reset?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pair]);

    useEffect(() => {
        if (positionSize === 0) return;
        if (!isPositionSizeSet) return;

        if (price) {
            const fromUSD = pair?.from === "USD";

            const newCollateral = fromUSD
                ? positionSize / (price * leverage)
                : (positionSize * price) / leverage;
            if (isFinite(newCollateral)) {
                setCollateral(Number(newCollateral.toFixed(6)));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pair?.from,
        price,
        leverage,
        isPositionSizeSet,
        positionSize,
        orderType,
        orderPrice,
    ]);

    useEffect(() => {
        if (collateral === 0) return;
        if (isPositionSizeSet) return;

        if (price) {
            const fromUSD = pair?.from === "USD";

            let newPositionSize = fromUSD
                ? collateral * leverage * price
                : (collateral * leverage) / price;
            let newCollateral = fromUSD
                ? newPositionSize * price * leverage
                : (newPositionSize * price) / leverage;

            if (maxCollateral < newCollateral) {
                newPositionSize = fromUSD
                    ? maxCollateral * price
                    : maxCollateral / price;
            }

            if (newPositionSize < 0.000001) {
                setPositionSize(0.0);
            } else {
                setPositionSize(newPositionSize);
            }
        }
    }, [
        pair?.from,
        collateral,
        leverage,
        isPositionSizeSet,
        price,
        maxCollateral,
        setPositionSize,
    ]);

    useEffect(() => {
        if (pair && leverage >= maxLeverage) setLeverage(maxLeverage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pair, leverage]);

    const trackTradeEvents = useCallback(
        (eventName: string, eventProps: any) => {
            trackEvent(eventName, {
                ...eventProps,
                address,
                from: pair?.from,
                to: pair?.to,
                orderType: orderType,
                collateral,
                leverage,
                positionSize,
                type,
            });
        },
        [
            address,
            pair,
            collateral,
            leverage,
            positionSize,
            type,
            orderType,
            trackEvent,
        ]
    );

    const debounce = (func: any, delay: any) => {
        let timer: any;
        //@ts-ignore
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                //@ts-ignore
                func.apply(this, args);
            }, delay);
        };
    };

    const trackTradeEventsRef = useRef(trackTradeEvents);

    const debouncedTrackEvent = useMemo(
        () =>
            debounce((value: number) => {
                trackTradeEventsRef.current(TRADE_EVENT_NAMES.CHANGE_CLL, {
                    newValue: value,
                });
            }, 1000),
        []
    );

    const onChangeCollateral = useCallback(
        (value: number) => {
            const fromUSD = pair?.from === "USD";

            if (!value) positionSizeInputRef.current?.reset();

            if (price) {
                setCollateral(value);
                debouncedTrackEvent(value);
                let newPositionSize = fromUSD
                    ? value * leverage * price
                    : (value * leverage) / price;

                if (newPositionSize < 0.000001) {
                    setPositionSize(0.0);
                } else {
                    setPositionSize(newPositionSize);
                }
            }
            if (isPositionSizeSet) setIsPositionSizeSet(false);
        },
        [
            isPositionSizeSet,
            leverage,
            pair?.from,
            price,
            setCollateral,
            setIsPositionSizeSet,
            setPositionSize,
            debouncedTrackEvent,
        ]
    );

    const onChangePositionSize = useCallback(
        (value: number) => {
            const fromUSD = pair?.from === "USD";

            if (!value) collateralInputRef.current?.reset();

            if (price) {
                let newPositionSize = value;
                let newCollateral = fromUSD
                    ? Number((value / (leverage * price)).toFixed(6))
                    : Number(((value * price) / leverage).toFixed(6));

                if (maxCollateral < newCollateral) {
                    newCollateral = maxCollateral;
                    newPositionSize = fromUSD
                        ? maxCollateral * price
                        : maxCollateral / price;
                }

                setPositionSize(newPositionSize);
                setCollateral(newCollateral);
            }
            if (!isPositionSizeSet) setIsPositionSizeSet(true);
        },
        [
            pair?.from,
            price,
            isPositionSizeSet,
            setIsPositionSizeSet,
            leverage,
            maxCollateral,
            setPositionSize,
            setCollateral,
        ]
    );

    const debouncedTrackEventLeverage = useMemo(
        () =>
            debounce((value: number) => {
                trackTradeEventsRef.current(TRADE_EVENT_NAMES.CHANGE_LEVERAGE, {
                    newValue: value,
                });
            }, 1000),
        []
    );

    const onChangeLeverage = useCallback(
        (value: number | null) => {
            const fromUSD = pair?.from === "USD";

            if (value == null) return;
            setLeverage(value);
            debouncedTrackEventLeverage(value);
            if (price) {
                if (isPositionSizeSet) {
                    const newCollateral = fromUSD
                        ? Number((positionSize / (leverage * price)).toFixed(6))
                        : Number(
                              ((positionSize * price) / leverage).toFixed(6)
                          );

                    setCollateral(newCollateral);
                    return;
                }

                const newPositionSize = fromUSD
                    ? (collateral * value - fees.value) * price
                    : (collateral * value - fees.value) / price;

                setPositionSize(newPositionSize);
            }
        },
        [
            pair?.from,
            setLeverage,
            price,
            isPositionSizeSet,
            collateral,
            fees.value,
            setPositionSize,
            positionSize,
            leverage,
            setCollateral,
            debouncedTrackEventLeverage,
        ]
    );

    const onSubmit = async () => {
        try {
            if (isTradingDisabled) {
                notify({
                    title: settings.language.screens.trade.disabled.title,
                    description:
                        settings.language?.screens.trade.disabled.description,
                });
                return;
            }
            if (!collateral || !address || !pair) return;
            if (orderType === PositionOrderTypes.Market) {
                if (!marketPrice?.mid) return;
            } else {
                if (!orderPrice) return;
            }

            if (settings.trade.openOrderModal) {
                openConfirmModal();
            } else {
                setIsLoading(true);
                await createOrder();
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            console.warn(err);
        }
    };

    const onTrade = useCallback(async () => {
        onChangeStopLoss(0);
        onChangeTakeProfit(0);

        stopLossInputRef.current?.reset();
        takeProfitInputRef.current?.reset();
        setIsConfirmModalVisible(false);
    }, [
        onChangeStopLoss,
        onChangeTakeProfit,
        stopLossInputRef,
        takeProfitInputRef,
    ]);

    const onChangeSymbol = (from: string) => {
        changePair(from);
    };

    const onChangeType = useCallback(
        (type: PositionTypes) => {
            setType(type);
        },
        [setType]
    );

    const onChangeOrderType = useCallback(
        (type: PositionOrderTypes) => {
            if (type !== PositionOrderTypes.Market) {
                setTimeout(() => {
                    orderPriceRef?.current?.input?.focus();
                }, 0);
            }
            setOrderPrice(0);
            orderPriceRef.current?.reset?.();
            trackTradeEvents(TRADE_EVENT_NAMES.CHANGE_OT, {});
            setOrderType(type);
        },
        [setOrderPrice, setOrderType, trackTradeEvents]
    );

    const onChangeOrderPrice = useCallback(
        (price: number) => {
            setOrderPrice(price);
        },
        [setOrderPrice]
    );

    const onChangeLeverageMark = useCallback(
        (mark: IMark) => {
            onChangeLeverage(mark.value);
        },
        [onChangeLeverage]
    );

    const openConfirmModal = useCallback(() => {
        setIsConfirmModalVisible(true);
    }, []);

    const onCloseConfirmModal = useCallback(() => {
        setIsConfirmModalVisible(false);
    }, []);

    const onTogglePositionSizeSet = useCallback(() => {
        setIsPositionSizeSet(!isPositionSizeSet);
    }, [isPositionSizeSet, setIsPositionSizeSet]);

    const createSmartWalletOrder = useCallback(
        async (trade: RawTrade, order: number) => {
            try {
                console.log("createSmartWalletOrder", smartAccountClient);

                const allowance = await getAllowance();
                const calls = [];
                if (!allowance || collateral <= allowance) {
                    calls.push({
                        to: currentChain.contracts.token,
                        data: encodeFunctionData({
                            abi: erc20Abi,
                            functionName: "approve",
                            args: [
                                getAddress(currentChain.contracts.storage),
                                parseUnits("1000000", 6),
                            ],
                        }),
                    });
                }

                const result = await sendSmartWalletOrder(
                    [
                        ...calls,
                        {
                            to: currentChain.contracts.trading,
                            data: encodeFunctionData({
                                abi: OstiumTrading__factory.abi,
                                functionName: "openTrade",
                                args: [
                                    //@ts-ignore
                                    trade,
                                    order,
                                    parseUnits("15", 2),
                                ],
                            }),
                        },
                    ],
                    true,
                    collateral
                );
                return result;
            } catch (err) {
                console.warn(err);
                throw new Error(
                    "There was a network issue. Please try again after 30 seconds."
                );
            } finally {
                await updateAllowance();
            }
        },
        [
            smartAccountClient,
            getAllowance,
            sendSmartWalletOrder,
            currentChain.contracts.trading,
            currentChain.contracts.storage,
            currentChain.contracts.token,
            updateAllowance,
            collateral,
        ]
    );

    const createWalletOrder = useCallback(
        async (trade: RawTrade, order: number) => {
            try {
                trackTradeEvents(TRADE_EVENT_NAMES.APPROVE_CLICK, {});
                const { hash, userSetAllowance } = await checkAllowance(
                    collateral
                );
                if (userSetAllowance != null) {
                    notify({
                        title: "New Allowance approved",
                        description: `${
                            currentChain.symbol
                        } allowance has been increased to ${formatPrice(
                            userSetAllowance,
                            {
                                currency: false,
                            }
                        )} ${currentChain.symbol}.`,
                        hash,
                        url: `${currentChain?.explorer}tx/${hash}`,
                    });
                    trackTradeEvents(TRADE_EVENT_NAMES.APPROVE_SUCCESS, {});
                }

                // const buttonPressedTimestamp = Date.now();

                // let orderId;
                // tradingContract.on("MarketOpenOrderInitiated", (_orderId) => {
                //     alert("asd");
                //     orderId = _orderId;
                //     console.warn("asd2");
                // });

                // const openOrderTimestamp = Date.now();

                //         const common = {
                //             orderId: _orderId,
                //             address,
                //             browser: "",
                //             wallet: "",
                //             origin: "frontend",
                //         };

                //         try {
                //             const results = await Promise.all([
                //                 fetch("/api/log", {
                //                     method: "POST",
                //                     body: new URLSearch({
                //                         ...common,
                //                         timestamp: buttonPressedTimestamp,
                //                         action: "buy-button-pressed",
                //                     }),
                //                 }),
                //                 fetch("/api/log", {
                //                     method: "POST",
                //                     body: JSON.stringify({
                //                         ...common,
                //                         timestamp: openOrderTimestamp,
                //                         action: "trade-opened",
                //                     }),
                //                 }),
                //             ]);

                //             console.warn(results);
                //         } catch (err) {
                //             console.warn(err);
                //         }
                trackTradeEvents(TRADE_EVENT_NAMES.TRADE_CLICK, {});
                const tx = await tradingContract.openTrade(
                    trade,
                    order,
                    parseUnits("15", 2)
                );

                // @ts-ignore
                await provider.waitForTransaction(tx.hash, 1);
                trackTradeEvents(TRADE_EVENT_NAMES.TRADE_SUCCESS, {});
                // while (!orderId) {
                //     await new Promise((resolve) => setTimeout(resolve, 1000));
                // }

                // tradingContract.removeAllListeners("MarketOpenOrderInitiated");
                return tx;
            } catch (err) {
                console.warn(err);
                throw new Error(err as any);
            } finally {
                await updateAllowance();
            }
        },
        [
            checkAllowance,
            collateral,
            currentChain?.explorer,
            currentChain.symbol,
            notify,
            provider,
            tradingContract,
            updateAllowance,
            trackTradeEvents,
        ]
    );

    const createOrder = useCallback(async () => {
        try {
            const trade: RawTrade = {
                pairIndex: Number(pair?.id),
                index: 0,
                trader: address as string,
                collateral: parseUnits(collateral?.toString(), 6),
                openPrice: parseUnits(
                    (
                        (orderType === PositionOrderTypes.Market
                            ? marketPrice?.mid
                            : orderPrice) || 0
                    ).toString(),
                    18
                ),
                buy: type === PositionTypes.Long,
                leverage: parseUnits(leverage?.toString(), 2),
                // TODO: trigger an error to see error codes
                // leverage: parseUnits("10000", 2),
                tp: takeProfit ? parseUnits(takeProfit.toString(), 18) : 0,
                sl: stopLoss ? parseUnits(stopLoss?.toString(), 18) : 0,
            };
            const order =
                orderType === PositionOrderTypes.Market
                    ? 0
                    : orderType === PositionOrderTypes.Limit
                    ? 1
                    : 2;

            const result =
                smartAccountClient && isSmartAccountEnabled
                    ? await createSmartWalletOrder(trade, order)
                    : await createWalletOrder(trade, order);

            if (result && result.isError) {
                notify({
                    title: result?.title,
                    description: result?.description,
                });
                return;
            }

            notify({
                title: `${capitalizeFirstLetter(
                    orderType.toLowerCase()
                )} Order Initiated`,
                description: "Your order has been initiated.",
                type: "Trade",
                data: {
                    trade: {
                        pair,
                        collateral: trade?.collateral,
                        leverage: trade?.leverage,
                        isBuy: trade?.buy,
                        openPrice: trade?.openPrice,
                        takeProfitPrice: trade?.tp || undefined,
                        stopLossPrice: trade?.sl || undefined,
                        tradeNotional: parseUnits(positionSize?.toString(), 18),
                    },
                },
                autoClose: 10000,
                // @ts-ignore
                hash: result?.hash,
                // @ts-ignore
                url: `${currentChain?.explorer}tx/${result?.hash}`,
            });
        } catch (err) {
            captureException(err);
            const error = parseWeb3Error(err as any);
            notify({
                title: "Error",
                description: `${error.code}\n${error.message}`,
            });
        }
    }, [
        address,
        collateral,
        createSmartWalletOrder,
        createWalletOrder,
        currentChain?.explorer,
        leverage,
        marketPrice?.mid,
        notify,
        orderPrice,
        orderType,
        pair,
        positionSize,
        smartAccountClient,
        stopLoss,
        takeProfit,
        type,
        isSmartAccountEnabled,
    ]);

    return {
        isLoading,
        currentChain,
        price,
        priceRaw,
        marketPrice,
        address,
        pairs,
        pair,
        allowance,

        fees,
        type,
        orderType,
        orderPrice,
        collateral,
        collateralAtOpen,
        leverage,
        positionSize,
        exposure,
        stopLoss,
        stopLossPercent,
        selectedStopLoss,
        stopLossLabel,
        takeProfit,
        takeProfitPercent,
        maxTakeProfitPercent,
        takeProfitLabel,
        selectedTakeProfit,
        liquidationPrice,
        takeProfitMarks,
        executionPrice,
        isStopLossValid,
        isTakeProfitValid,

        isWithinExposureLimit,
        maxCollateral,
        maxLeverage,
        minLeverage,
        maxStopLoss,
        maxTakeProfit,
        minStopLoss,
        minTakeProfit,

        orderPriceRef,
        collateralRef,
        collateralInputRef,
        positionSizeInputRef,
        stopLossInputRef,
        takeProfitInputRef,
        minimumPositionSize,

        onChangeSymbol,
        onChangeType,
        onChangePositionSize,
        onChangeCollateral,
        onChangeOrderType,
        onChangeLeverage,
        onChangeOrderPrice,
        onChangeLeverageMark,
        onChangeStopLoss,
        onChangeTakeProfit,
        onChangeStopLossMark,
        onChangeTakeProfitMark,
        onBlurTakeProfit,
        onBlurStopLoss,
        onTogglePositionSizeSet,

        createOrder,
        onTrade,
        onSubmit,

        onCloseConfirmModal,
        isConfirmModalVisible,
        isPositionSizeSet,
    };
}

const TradeFormProvider = ({ children }: { children: JSX.Element }) => {
    const value = useForm();

    return (
        <TradeFormContext.Provider value={value}>
            {children}
        </TradeFormContext.Provider>
    );
};

const useTradeFormContext = () => useContext(TradeFormContext);

export { TradeFormProvider, useTradeFormContext };

const GET_VAULT_BALANCE = gql`
    query getVaultBalance @api(name: subgraph) {
        vault(id: "usdc-vault") {
            id
            accPnlPerTokenUsed
            rewardsPerToken
            shares
        }
    }
`;
