import React, { useEffect, useContext, useRef } from "react";
import { loadSpace, SpaceApi } from "@usersnap/browser";
import { useOstiumAccount } from "./hooks/useOstiumAccount";

export const TrackingContext = React.createContext(
    {} as {
        usersnap: SpaceApi | null;
        openUsersnap: () => void;
    }
);

function useTracking() {
    const { address } = useOstiumAccount();
    const usersnap = useRef<SpaceApi | null>(null);

    useEffect(() => {
        const init = async () => {
            if (!process.env.NEXT_PUBLIC_USERSNAP_SPACE_API_KEY) return;
            try {
                const result = await loadSpace(
                    process.env.NEXT_PUBLIC_USERSNAP_SPACE_API_KEY
                );

                result?.init?.({
                    user: {
                        // email: `${address}@ostium.app`,
                        userId: address as string,
                    },
                    useLocalStorage: true, // whether allowed to use user's local storage
                    useSystemFonts: false, // whether widget should use browser default font rather than loading external one
                    collectGeoLocation: "all",
                    custom: {
                        wallet: address,
                    },
                });

                usersnap.current = result;
            } catch (err) {
                console.warn(err);
            }
        };

        init();
    }, [address]);

    const openUsersnap = async () => {
        if (!process.env.NEXT_PUBLIC_USERSNAP_PROJECT_API_KEY) return;
        try {
            const result = await usersnap?.current?.show?.(
                process.env.NEXT_PUBLIC_USERSNAP_PROJECT_API_KEY
            );
            result?.open?.({});
        } catch (err) {
            console.warn(err);
        }
    };

    return {
        usersnap: usersnap.current,
        openUsersnap,
    };
}

export const TrackingProvider = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => {
    const values = useTracking();

    return (
        <TrackingContext.Provider value={values}>
            {children}
        </TrackingContext.Provider>
    );
};

export function useTrackingContext() {
    return useContext(TrackingContext);
}
