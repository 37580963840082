import { Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useMedia } from '@hooks/useMedia';
import { Button } from "@molecules/Button";
import { memo, useMemo } from "react";
import { useTheme } from "styled-components/native";

export const SmartWalletAlert = memo(() => {
    const theme = useTheme();
    const { isSmartAccountEnabled, gotIt, setGotIt } = useAppContext();
    const { isTabletOrMobileDevice } = useMedia();
    const bannerText = useMemo(() => {
        if (!isSmartAccountEnabled && !gotIt && !isTabletOrMobileDevice) {
            return `Account Abstraction is now live! If you're logged-in with a wallet and want to use smart account, click "Enable Account" button on the top-right. To disable, click wallet button on the top-right and click "Disable Account".`;
        } else return null;
    }, [isSmartAccountEnabled, gotIt, isTabletOrMobileDevice]);

    if (bannerText) {
        return (
            <Flex
                align="center"
                justify="center"
                gap={theme.spacing.smallest}
                style={{
                    height: 25,
                    backgroundColor: theme.color.rgba(theme.color.green, 0.7),
                }}
            >
                <Text smallest semiBold style={{ textAlign: "center" }}>
                    {bannerText}
                </Text>

                <Button
                    tiny
                    onPress={() => {
                        setGotIt(true);
                        window?.localStorage.setItem("aa_banner", "true");
                    }}
                    text="Got it"
                    white
                    style={{ height: 20 }}
                />
            </Flex>
        );
    }
    return null;
});
