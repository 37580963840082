import { Box } from "@atoms/Flex";
import Intercom from "@intercom/messenger-js-sdk";
import Store, { SimpleStore } from "@contexts/stores";
import { ConferenceLayout } from "@screens/ETHCC/layout";
import { StaticLayout } from "@screens/Legal/layout";
import { PortfolioLayout } from "@screens/Portfolio/layout";
import { TradeLayout } from "@screens/Trade/layout";
import { WaitlistLayout } from "@screens/Waitlist/layout";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";

const Header = dynamic(() => import("@screens/components/Header"));
export const RootLayout = ({
    pageProps,
    Component,
}: {
    pageProps: any;
    Component: any;
}) => {
    const { address } = useOstiumAccount();
    useEffect(() => {
        Intercom({
            app_id: "li6g9p5g",
            wallet_address: address || "not connected",
            custom_attributes: {
                wallet_address: address || "not connected",
            },
        });
    }, [address]);

    if (
        pageProps.layout === "empty" ||
        pageProps.layout === "waitlist" ||
        pageProps.layout === "conference"
    ) {
        return (
            <SimpleStore pageProps={pageProps}>
                <LayoutComponent {...pageProps}>
                    <Component {...pageProps} />
                </LayoutComponent>
            </SimpleStore>
        );
    }

    return (
        <Store pageProps={pageProps}>
            <Header />

            <LayoutComponent {...pageProps}>
                <Component {...pageProps} />
            </LayoutComponent>
        </Store>
    );
};

const LayoutComponent = ({
    layout,
    children,
    ...props
}: {
    layout: string;
    header?: boolean;
    children: JSX.Element;
}) => {
    switch (layout) {
        case "waitlist":
            return <WaitlistLayout {...props}>{children}</WaitlistLayout>;
        case "trade":
            return <TradeLayout {...props}>{children}</TradeLayout>;
        case "portfolio":
            return <PortfolioLayout {...props}>{children}</PortfolioLayout>;
        case "static":
            return <StaticLayout {...props}>{children}</StaticLayout>;
        case "conference":
            return <ConferenceLayout {...props}>{children}</ConferenceLayout>;
        case "empty":
            <Box flex={1}>{children}</Box>;
        default:
            return children;
    }
};
