import { useWeb3Context } from "@contexts/web3";
import { LpNft, LpShare, Pair, Vault } from "gql/graphql";

// @ts-ignore
import { OstiumVault__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumVault__factory";

import { OstiumVault } from "@ostium/smart-contracts/dist/typechain/src/OstiumVault";

import { useAppContext } from "@contexts/app";
import { gql, useQuery } from "@hooks/useApollo";
import { ITab } from "@screens/Trade/components/Form/TabBar";
import { BigNumber } from "@utils";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { formatUnits } from "viem";
import { DAY_IN_SECONDS } from "./utils";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";

export enum VaultTab {
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW",
    UNLOCK = "UNLOCK",
}

export const VaultContext = createContext(
    {} as {
        currentTab: ITab;
        vault?: Vault;
        vaultContract: OstiumVault;
        lockedDeposits: LpNft[];
        totalLocked: number | null;
        totalDeposited: number | null;
        collateralizationRatio: number;
        isLoadingVault: boolean;
        totalDepositedAssets: number;
        totalLockedAssets: number;

        epochNumber: number;
        epochLength: number;
        timelock: number;
        timelockEndDate: Date;
        startDate: Date;
        endDate: Date;
        withdrawEnabledEndDate: number;
        withdrawalEnabledLength: number;
        onChangeTab: (tab: ITab) => void;
    }
);
const calculateAPR = (currentPrices: number[], pastPrices: number[]): number => {
    const X = 30; // Number of days
    const N = 15; // Number of days for averaging
    if (currentPrices.length !== N || pastPrices.length !== N) {
        console.error('Invalid data: Arrays must have exactly 15 entries each.');
        return 0;
    }

    // Average the logarithmic changes
    const avgLogChange =
        currentPrices.reduce((sum, currentPrice, i) => {
            const pastPrice = pastPrices[i];
            if (pastPrice <= 0 || currentPrice <= 0) {
                console.error('Invalid price data: Prices must be greater than 0.');
                return sum;
            }
            return sum + Math.log(currentPrice / pastPrice);
        }, 0) / N;

    const apr = (365 / X) * avgLogChange;
    return apr;
};

const calculateAPY = (apr: number): number => {
    // APY can be calculated directly from APR
    return apr;
    // return Math.pow(1 + apr / 365, 365) - 1;
};

// Query for the last 45 days' share prices
const SHARE_TO_ASSETS_PRICE_DAILIES = gql`
    query shareToAssetsPriceDailies @api(name: subgraph) {
        shareToAssetsPriceDailies(
            first: 45
            orderBy: timestamp
            orderDirection: desc
        ) {
            id
            day
            timestamp
            sharePrice
        }
    }
`;

export function useSharePrice() {
    const [price, setPrice] = useState(Number(formatUnits(BigInt("0"), 18)));

    const { loading } = useQuery(GET_SHARE_PRICE, {
        pollInterval: 1000,
        onCompleted: (data) => {
            setPrice(Number(formatUnits(data?.vault?.sharePrice, 18)));
        },
    });

    return { price, loading };
}

export const useAPY = () => {
    const { price: currentPrice } = useSharePrice();
    const { data: shareToAssetsPriceDailies } = useQuery(
        SHARE_TO_ASSETS_PRICE_DAILIES,
        {
            pollInterval: 60000,
        }
    );

    const { apy, apr } = useMemo(() => {
        if (!currentPrice || !shareToAssetsPriceDailies)
            return { apy: 0, apr: 0 };

        const shareToAssetsPrices =
            shareToAssetsPriceDailies?.shareToAssetsPriceDailies;
        if (!shareToAssetsPrices || shareToAssetsPrices.length < 45)
            return { apy: 0, apr: 0 };

        // Extract current and past prices for averaging
        const currentPrices = shareToAssetsPrices
            .slice(0, 15)
            .map((entry: any) => Number(formatUnits(entry.sharePrice, 18)));

        const pastPrices = shareToAssetsPrices
            .slice(30, 45)
            .map((entry: any) => Number(formatUnits(entry.sharePrice, 18)));

        if (currentPrices.length !== 15 || pastPrices.length !== 15) {
            console.error('Insufficient data: Not enough prices for averaging.');
            return { apy: 0, apr: 0 };
        }

        const apr = calculateAPR(currentPrices, pastPrices);
        const apy = calculateAPY(apr);

        return { apy, apr };
    }, [currentPrice, shareToAssetsPriceDailies]);

    return { apy, apr };
};

const useVault = () => {
    const { currentChain, provider, signer, isOtherNetwork } = useWeb3Context();
    const { address } = useOstiumAccount();
    const { initial } = useAppContext();

    const [collateralizationRatio, setCollateralizationRatio] = useState(0);
    const [vault, setVault] = useState(initial.vault);

    const { data, loading: isLoadingVault } = useQuery(GET_DATA, {
        variables: {
            id: address?.toLowerCase(),
        },
        pollInterval: 5000,
        onCompleted(data) {
            setVault(data.vault);
        },
    });

    const lockedDeposits: LpNft[] = useMemo(() => {
        return data?.lpNFTs || [];
    }, [data]);

    const shares: LpShare = useMemo(() => {
        return data?.lpShares?.[0] || null;
    }, [data?.lpShares]);

    const [currentTab, setCurrentTab] = useState<ITab>({
        id: "deposit",
        text: "Deposit",
    });

    const epochNumber = useMemo(
        () => Number(vault?.currentEpoch) || 0,
        [vault?.currentEpoch]
    );
    const startDate = useMemo(
        () =>
            Number(vault?.currentEpochStart)
                ? new Date(vault?.currentEpochStart * 1000)
                : new Date(),
        [vault?.currentEpochStart]
    );
    const endDate = useMemo(
        () =>
            Number(vault?.currentEpochEnd)
                ? new Date(vault?.currentEpochEnd * 1000)
                : new Date(),
        [vault?.currentEpochEnd]
    );

    const epochLength = useMemo(() => {
        return (
            Number(vault?.currentEpochEnd) - Number(vault?.currentEpochStart)
        );
    }, [vault?.currentEpochEnd, vault?.currentEpochStart]);

    const withdrawalEnabledLength = epochLength / 3;
    const withdrawEnabledEndDate = useMemo(() => {
        return new Date().setTime(
            Number(vault?.currentEpochStart) + withdrawalEnabledLength
        );
    }, [vault?.currentEpochStart, withdrawalEnabledLength]);

    const timelock = useMemo(() => {
        if (collateralizationRatio >= 120) return 1;
        if (collateralizationRatio >= 110) return 2;
        return 3;
    }, [collateralizationRatio]);

    const timelockEndDate = useMemo(() => {
        const duration = timelock * epochLength;

        if (!Number(vault?.currentEpochStart))
            return new Date(new Date().getTime() + duration * 1000);

        return new Date((Number(vault?.currentEpochStart) + duration) * 1000);
    }, [epochLength, timelock, vault?.currentEpochStart]);

    const totalLocked = useMemo(
        () =>
            lockedDeposits
                ? Number(
                    formatUnits(
                        lockedDeposits
                            .reduce((acc, item) => {
                                if (item.isUnlocked) return acc;
                                return acc.add(item.shares);
                            }, BigNumber.from("0"))
                            .toBigInt(),
                        6
                    )
                )
                : 0,
        [lockedDeposits]
    );

    const totalLockedAssets = useMemo(
        () =>
            lockedDeposits
                ? Number(
                    formatUnits(
                        lockedDeposits
                            .reduce((acc, item) => {
                                if (item.isUnlocked) return acc;
                                return acc.add(item.assetsDeposited);
                            }, BigNumber.from("0"))
                            .toBigInt(),
                        6
                    )
                )
                : 0,
        [lockedDeposits]
    );

    const totalDepositedAssets = useMemo(
        () => (shares?.assets ? Number(formatUnits(shares?.assets, 6)) : 0),
        [shares?.assets]
    );

    const totalDeposited = useMemo(
        () => (shares?.shares ? Number(formatUnits(shares?.shares, 6)) : 0),
        [shares?.shares]
    );

    //@ts-ignore
    const vaultContract: OstiumVault = useMemo(() => {
        if (currentChain.contracts.vault)
            return OstiumVault__factory?.connect(
                currentChain.contracts.vault,
                address ? signer : provider
            );
        return null;
    }, [currentChain.contracts.vault, address, signer, provider]);

    const getCollateralizationRatio = useCallback(async () => {
        try {
            const result = await vaultContract?.collateralizationP();
            setCollateralizationRatio(Number(formatUnits(BigInt(result), 2)));
        } catch (err) {
            console.warn(err);
        }
    }, [vaultContract]);

    useEffect(() => {
        if (vaultContract && !isOtherNetwork) getCollateralizationRatio();
    }, [
        address,
        vaultContract,
        currentTab,
        isOtherNetwork,
        getCollateralizationRatio,
    ]);

    const onChangeTab = useCallback(
        (tab: ITab) => {
            setCurrentTab(tab);
        },
        [setCurrentTab]
    );

    return {
        currentTab,
        vaultContract,
        vault,
        totalLocked,
        totalDeposited,
        lockedDeposits,
        collateralizationRatio,
        timelock,
        timelockEndDate,
        epochNumber,
        startDate,
        endDate,
        isLoadingVault,
        totalDepositedAssets,
        totalLockedAssets,
        epochLength,
        withdrawEnabledEndDate,
        withdrawalEnabledLength,
        onChangeTab,
    };
};

export const VaultProvider = ({ children }: { children: JSX.Element }) => {
    const value = useVault();
    return (
        <VaultContext.Provider value={value}>{children}</VaultContext.Provider>
    );
};

export const useVaultContext = () => useContext(VaultContext);

// Old, to be removed
export type StakeType = {
    pair: Pair;
    amount: number;
    hide?: boolean;
};

const GET_SHARE_PRICE = gql`
    query getSharePrice($id: Bytes) @api(name: subgraph) {
        vault(id: "usdc-vault") {
            sharePrice
        }
    }
`;

const GET_DATA = gql`
    query getShares($id: Bytes) @api(name: subgraph) {
        vault(id: "usdc-vault") {
            id
            currentEpoch
            currentEpochStart
            currentEpochEnd
            assets
            shares
            totalLockedUsers

            sharePrice
            accPnlPerTokenUsed
            currentMaxSupply
            rewardsPerToken
            maxDiscountP
            maxDiscountThresholdP
        }
        lpShares(first: 1000, where: { user: $id }) {
            id
            shares
            assets
        }
        lpNFTs(
            first: 1000
            where: { user: $id }
            orderBy: atTime
            orderDirection: desc
        ) {
            id
            assetsDeposited
            assetsDiscount
            atTime
            isUnlocked
            lockDuration
            shares
        }
    }
`;
