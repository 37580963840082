import { Avatar } from "@atoms/Avatar";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useReferrals } from "@contexts/hooks/useReferrals";
import { useNotificationContext } from "@contexts/notification";
import { Button } from "@molecules/Button";
import { InputRef } from "@molecules/Input";
import { Statistic } from "@molecules/Statistic";
import { List } from "@organisms/List";
import { LIST_ITEM_HEIGHT } from "@screens/Trade/components/List/utils";
import { formatAddress, formatPrice } from "@utils";
import { UserFe, type User } from "gql/graphql";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ListRenderItem, Pressable, TextInput } from "react-native";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import { Icon } from "@atoms/Icon";
import { Image } from "@atoms/Image";
import { TabBarItem, type ITab } from "@screens/Trade/components/Form/TabBar";
import { useUser } from "@contexts/hooks/useUser";

type ReferralUser = User & { userFE: UserFe };

const openPopup = (url: string) => {
    const width = 500;
    const height = 500;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
        url,
        "_blank",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
};

export const Referrals = ({ onClose }: any) => {
    const theme = useTheme();
    const { userFE, refetchUser } = useAppContext();
    const { address } = useAccount();
    const { notify } = useNotificationContext();
    const [expanded, setExpanded] = useState(true);
    const [buttonText, setButtonText] = useState("Submit Code");
    const {
        referredUsers,
        totalReferrals,
        totalReferralDeposits,
        totalReferralVolume,
        isLoading,
        isLoadingReferredUsers,
    } = useReferrals(address);

    useEffect(() => {
        if (address) {
            refetchUser();
        }
    }, [address, refetchUser]);

    const refCode = useMemo(() => {
        return userFE?.referralCode || userFE?.accessCode || "";
    }, [userFE]);

    const accessCode = useMemo(() => {
        return userFE?.accessCode || "";
    }, [userFE]);

    const referralUrl = useMemo(() => {
        if (userFE?.referralCode) {
            const currentUrl = window.location.href;
            const refParam = "ref=" + (userFE?.referralCode || "");
            const separator = currentUrl.includes("?") ? "&" : "?";
            const newUrl =
                "https://ostium.app/trade?from=CL&to=USD&" +
                (refParam ? refParam : "");
            return newUrl;
        }
        return "";
    }, [userFE]);

    const inputRef = useRef<InputRef>(null);

    const onCopy = useCallback(() => {
        notify({
            title: "Referral link copied to clipboard",
        });
        inputRef.current?.input?.focus?.();
        navigator.clipboard.writeText(referralUrl);
    }, [notify, referralUrl]);

    const openTelegram = (referralUrl?: string) => {
        const encodedUrl = encodeURIComponent(referralUrl as string);
        openPopup(`https://t.me/share/url?url=${encodedUrl}`);
    };

    const openTwitter = (referralUrl?: string) => {
        const encodedUrl = encodeURIComponent(referralUrl as string);
        openPopup(`https://x.com/intent/tweet?text=${encodedUrl}`);
    };

    const onCodeCopy = useCallback(() => {
        notify({
            title: "Access code copied to clipboard",
        });
        inputRef.current?.input?.focus?.();
        //@ts-ignore
        navigator.clipboard.writeText(refCode);
    }, [notify, refCode]);

    const [tab, setTab] = useState<ITab>({
        text: "Your code",
        id: "your-code",
    });

    const { validateAccessCode } = useUser({ address });

    const inputRefs = useRef<(TextInput | null)[]>([]);
    const [code, setCode] = useState(
        accessCode.split("") || ["", "", "", "", ""]
    );
    const [error, setError] = useState(false);
    const fullCode = useMemo(() => code.join(""), [code]);
    const isValidFiveDigitCode = fullCode.length === 5 || fullCode === "";

    const handleChangeText = (text: string, index: number) => {
        // Detect if the entire code is being pasted
        if (text.length > 1) {
            const newCode = text.slice(0, 5).toUpperCase().split("");
            setCode(newCode);
            // Move focus to the next input or submit the form
            (inputRefs.current[4] as TextInput | null)?.focus?.();
            return;
        }

        // Handle regular character input
        const newCode = [...code];
        newCode[index] = text.toUpperCase();
        setCode(newCode);

        if (text.length === 1 && index < inputRefs.current.length - 1) {
            (inputRefs.current[index + 1] as TextInput | null)?.focus?.();
        }
    };

    const handleKeyPress = ({ nativeEvent }: any, index: number) => {
        if (nativeEvent.key === "Backspace" && index > 0 && !code[index]) {
            (inputRefs.current[index - 1] as TextInput | null)?.focus?.();
        }
    };

    const tabs = [
        {
            text: "Your code",
            id: "your-code",
        },
        {
            text: "Enter code",
            id: "enter-code",
        },
    ];

    const onValidateAccessCode = useCallback(async () => {
        if (!isValidFiveDigitCode) return;
        const data = await validateAccessCode(fullCode.toUpperCase());
        if (data) {
            if (data.isAccessCodeValid) {
                notify({
                    title: "Access code validated",
                    type: "Success",
                });
                setError(false);
                await refetchUser();
                setButtonText("✓ Code Used");
            } else {
                setError(true);
                notify({
                    title: "Access code invalid",
                    type: "Error",
                });
            }
        }
    }, [
        isValidFiveDigitCode,
        validateAccessCode,
        fullCode,
        notify,
        refetchUser,
    ]);

    const getReferralScore = useCallback(() => {
        // Referral score calculation
        const score =
            3 * Math.sqrt(totalReferrals) +
            0.01 * Math.sqrt(totalReferralVolume) +
            0.1 * Math.sqrt(totalReferralDeposits);

        // Round up the score to the nearest integer
        return Math.round(score).toLocaleString();
    }, [totalReferralDeposits, totalReferralVolume, totalReferrals]);

    return (
        <Box
            style={{
                padding: theme.spacing.bigger,
                paddingTop: 0,
            }}
            gap={theme.spacing.bigger}
        >
            <Text
                smaller
                color={theme.color.rgba(theme.color.white, 0.7)}
                lineHeight={theme.spacing.bigger}
                lineSpacing={theme.spacing.bigger}
            >
                Earn trading rewards when you successfully refer a friend to
                deposit or trade on Ostium.
            </Text>
            <Statistic
                label="Referral score"
                value={getReferralScore()}
                tooltip={{
                    title: "Referral Score",
                    minWidth: true,
                    content:
                        "The referral score is a function of number of users, referred volume, referred deposits.",
                }}
                style={{
                    marginLeft: 10,
                }}
            />

            {/* TABS */}
            <Flex
                style={{
                    borderWidth: 0,
                    height: 36,
                    width: "45%",
                }}
            >
                {tabs.map((item, index) => {
                    const isFirst = index === 0;
                    const isLast = index === tabs.length - 1;

                    return (
                        <TabBarItem
                            key={`referrals-tab-bar-item-${item.id}-${index}`}
                            item={item}
                            isFirst={isFirst}
                            isLast={isLast}
                            onPress={() => setTab(item)}
                            style={{
                                borderRadius: 0,
                                backgroundColor: "transparent",
                            }}
                            textStyle={{
                                color:
                                    tab.id === item.id
                                        ? theme.color.white
                                        : theme.color.rgba(
                                              theme.color.white,
                                              0.4
                                          ),
                                fontSize: 14,
                                fontWeight: "normal",
                            }}
                        />
                    );
                })}
            </Flex>

            {/* ACCESS CODE */}
            {tab?.id === "access-code" && (
                <Flex
                    align="center"
                    gap={theme.spacing.smaller}
                    justify="space-between"
                    style={{
                        borderRadius: theme.spacing.smaller,
                        background: "#1B1B1B",
                        padding: theme.spacing.small,
                    }}
                >
                    <Flex align="center" gap={theme.spacing.smaller}>
                        <Text color="rgba(208, 219, 218, 0.60)" smaller>
                            Access code
                        </Text>
                        <Text ref={inputRef} color="white" smaller align="left">
                            {refCode}
                        </Text>
                    </Flex>

                    <Flex align="center" gap={theme.spacing.smaller}>
                        <Pressable
                            onPress={() =>
                                openTwitter(
                                    `Use my access code ${refCode} to start trading on Ostium` ||
                                        "https://ostium.app/trade"
                                )
                            }
                        >
                            <Image
                                source={`/assets/twitter.png`}
                                priority
                                height={20}
                                width={20}
                                fill={true}
                                alt={`/twitter`}
                            />
                        </Pressable>
                        <Pressable
                            onPress={() =>
                                openTelegram(
                                    `Use my access code ${refCode} to start trading on Ostium` ||
                                        "https://ostium.app/trade"
                                )
                            }
                        >
                            <Image
                                source={`/assets/telegram.png`}
                                priority
                                height={11}
                                width={13}
                                fill={true}
                                alt={`/telegram`}
                            />
                        </Pressable>
                        <Pressable onPress={onCodeCopy}>
                            <Image
                                source={`/assets/copy.png`}
                                priority
                                height={16}
                                width={16}
                                fill={true}
                                alt={`/copy`}
                            />
                        </Pressable>
                    </Flex>
                </Flex>
            )}

            {/* YOUR CODE - REFERRAL URL */}
            {tab?.id === "your-code" && (
                <Flex
                    align="center"
                    gap={theme.spacing.small}
                    justify="space-between"
                    style={{
                        borderRadius: theme.spacing.smaller,
                        background: "#1B1B1B",
                        padding: theme.spacing.big,
                    }}
                >
                    <Text color="rgba(208, 219, 218, 0.60)" smaller>
                        {"https://"}
                        <Text ref={inputRef} color="white" smaller>
                            {refCode
                                ? `ostium.app/trade?ref=${refCode}`
                                : `ostium.app/trade`}
                        </Text>
                    </Text>

                    <Flex align="center" gap={theme.spacing.smaller}>
                        <Pressable
                            onPress={() =>
                                openTwitter(
                                    referralUrl || "https://ostium.app/trade"
                                )
                            }
                        >
                            <Image
                                source={`/assets/twitter.png`}
                                priority
                                height={20}
                                width={20}
                                fill={true}
                                alt={`/twitter`}
                            />
                        </Pressable>
                        <Pressable
                            onPress={() =>
                                openTelegram(
                                    referralUrl || "https://ostium.app/trade"
                                )
                            }
                        >
                            <Image
                                source={`/assets/telegram.png`}
                                priority
                                height={11}
                                width={13}
                                fill={true}
                                alt={`/telegram`}
                            />
                        </Pressable>
                        <Pressable onPress={onCopy}>
                            <Image
                                source={`/assets/copy.png`}
                                priority
                                height={16}
                                width={16}
                                fill={true}
                                alt={`/copy`}
                            />
                        </Pressable>
                    </Flex>
                </Flex>
            )}

            {/* ENTER CODE - ACCESS CODE */}
            {tab?.id === "enter-code" && (
                <Flex
                    justify="space-between"
                    style={{
                        borderRadius: theme.spacing.smaller,
                        background: "#1B1B1B",
                        padding: theme.spacing.smaller,
                    }}
                >
                    <Flex gap={theme.spacing.smaller}>
                        {" "}
                        {[0, 1, 2, 3, 4].map((index) => (
                            <TextInput
                                key={index}
                                //@ts-ignore
                                ref={(ref) => (inputRefs.current[index] = ref)}
                                value={code[index]}
                                onChangeText={(text) => {
                                    if (error) setError(false);
                                    handleChangeText(text.toUpperCase(), index);
                                }}
                                editable={
                                    accessCode.length === 0 ||
                                    fullCode !== accessCode
                                }
                                onKeyPress={(e) => handleKeyPress(e, index)}
                                style={{
                                    borderWidth: 1,
                                    borderColor: !isValidFiveDigitCode
                                        ? theme.color.red
                                        : "",
                                    backgroundColor: theme.color.rgba(
                                        theme.color.white,
                                        0.1
                                    ),
                                    color: theme.color.white,
                                    textAlign: "center",
                                    fontSize: 16,
                                    width: 30,
                                    borderRadius: 5,
                                }}
                            />
                        ))}
                    </Flex>
                    <Button
                        text={
                            fullCode === accessCode && accessCode.length > 0
                                ? "✓ Code Used"
                                : buttonText
                        }
                        primary
                        stroke
                        disabled={
                            fullCode === accessCode ||
                            buttonText === "✓ Code Used"
                        }
                        onPress={onValidateAccessCode}
                    />
                </Flex>
            )}

            <Box
                style={{
                    marginTop: theme.spacing.big,
                    marginLeft: theme.spacing.tiny,
                }}
                gap={theme.spacing.biggest}
            >
                <Pressable onPress={() => setExpanded(!expanded)}>
                    <Flex
                        direction="row"
                        align="center"
                        style={{
                            cursor: "pointer",
                        }}
                        gap={theme.spacing.smaller}
                    >
                        <Icon
                            name="caret"
                            color={theme.color.rgba(theme.color.primary, 0.6)}
                            size={10}
                            rotate={expanded ? 90 : 0}
                        />
                        <Text
                            smaller
                            semiBold
                            color={theme.color.rgba(theme.color.white, 0.7)}
                        >
                            See past referrals
                        </Text>
                    </Flex>
                </Pressable>
                <Box
                    style={{
                        height: expanded ? "50px" : "0px",
                        overflow: "hidden",
                        transition: "height 0.5s ease-in-out",
                    }}
                >
                    <Flex
                        gap={theme.spacing.smaller}
                        style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                        justify="space-between"
                    >
                        <Statistic
                            label="Users"
                            value={totalReferrals}
                            loading={isLoading}
                            tooltip={{
                                title: "Referred Users",
                                minWidth: true,
                                content:
                                    "The total number of users referred to Ostium.",
                            }}
                        />
                        <Statistic
                            label="Deposits"
                            value={`${formatPrice(totalReferralDeposits, {
                                currency: false,
                                decimals: 0,
                            })} USDC`}
                            loading={isLoadingReferredUsers}
                            tooltip={{
                                title: "Deposits",
                                minWidth: true,
                                content:
                                    "The total amount of deposits made by referred users.",
                            }}
                        />
                        <Statistic
                            label="Volume"
                            value={`${formatPrice(totalReferralVolume, {
                                currency: false,
                                decimals: 0,
                            })} USDC`}
                            loading={isLoadingReferredUsers}
                            tooltip={{
                                title: "Volume",
                                minWidth: true,
                                content:
                                    "The total volume generated by referred users.",
                            }}
                        />
                    </Flex>
                </Box>
            </Box>

            <Flex gap={theme.spacing.medium} justify="end">
                <Button text="Close" primary={false} onPress={onClose} />
                <Button text="Copy Link" primary onPress={onCopy} />
            </Flex>

            {/* TODO: To be added if needed */}
            {false && referredUsers && (
                <>
                    <Box
                        style={{
                            minHeight: 110,
                            maxHeight: 320,
                            borderRadius: theme.radius.big,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                0.05
                            ),
                        }}
                    >
                        <ReferralsList
                            isLoading={isLoading}
                            users={referredUsers.users}
                        />
                    </Box>
                    {/* <Button
                        big
                        text="Copy URL"
                        onPress={onCopy}
                        primary
                        stroke
                        noBorder
                    /> */}
                </>
            )}
        </Box>
    );
};

const ReferralsList = ({
    users = [],
    isLoading,
}: {
    users: ReferralUser[];
    isLoading: boolean;
}) => {
    const theme = useTheme();

    const renderReferral: ListRenderItem<ReferralUser> = useMemo(
        () =>
            ({ item, index }) => {
                const isLast = index === users.length - 1;

                const username = item?.userFE?.username
                    ? item?.userFE?.username
                    : formatAddress(item.id);

                return (
                    <Flex
                        gap={theme.spacing.big}
                        justify="space-between"
                        align="center"
                        style={{
                            paddingHorizontal: theme.spacing.bigger,
                            height: LIST_ITEM_HEIGHT,
                            borderRadius: theme.radius,
                            borderBottomWidth: isLast ? 0 : 1,
                            borderBottomColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                        }}
                    >
                        <Flex align="center" gap={theme.spacing.big}>
                            <Avatar string={item.id} />
                            <Box gap={theme.spacing.tiny}>
                                <Text bold smaller color={theme.color.white}>
                                    {username}
                                </Text>
                            </Box>
                        </Flex>
                        <Box>
                            <Text small bold color={theme.color.white}>
                                {`${formatPrice(
                                    Number(formatUnits(item.totalVolume, 6)),
                                    { currency: false, decimals: 0 }
                                )} USDC`}
                            </Text>
                        </Box>
                    </Flex>
                );
            },
        [
            users?.length,
            theme.color,
            theme.radius,
            theme.spacing.big,
            theme.spacing.bigger,
            theme.spacing.tiny,
        ]
    );

    const renderEmpty = useMemo(() => {
        return (
            <Box
                style={{
                    padding: theme.spacing.bigger,
                    borderRadius: theme.radius,
                }}
            >
                <Text
                    smaller
                    color={theme.color.white}
                    lineHeight={theme.spacing.bigger}
                >
                    No referred users
                </Text>
            </Box>
        );
    }, [theme.color, theme.radius, theme.spacing.bigger]);

    return (
        <List
            data={users}
            renderItem={renderReferral}
            renderEmpty={renderEmpty}
            isLoading={isLoading}
            style={{
                minHeight: 100,
            }}
        />
    );
};
