import { useMemo, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { encodeFunctionData, erc20Abi, parseUnits } from "viem";
import { useNotificationContext } from "@contexts/notification";
import { useWeb3Context } from "@contexts/web3";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { useBalance } from "@hooks/useBalance";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Skeleton } from "@molecules/Skeleton";
import { Button } from "@molecules/Button";
import { Input } from "@molecules/Input";
import { formatPrice, getPairLogo } from "@utils";

export const WithdrawModal = ({
    onClose,
    sendSmartWalletOrder,
}: {
    onClose: Function;
    sendSmartWalletOrder: Function;
}) => {
    const theme = useTheme();
    const { currentChain } = useWeb3Context();
    const { address, wagmiAddress } = useOstiumAccount();
    const [amount, setAmount] = useState<number>(0.0);
    // we can put the wagmi address here as default value: useState<`0x${string}`>(wagmiAddress)
    const [addressToWithdraw, setAddressToWithdraw] = useState<`0x${string}`>(
        "" as `0x${string}`
    );
    const [loading, setLoading] = useState(false);
    const { notify } = useNotificationContext();

    const handleWithdraw = async () => {
        try {
            setLoading(true);
            const result = await sendSmartWalletOrder(
                [
                    {
                        to: currentChain.contracts.token,
                        data: encodeFunctionData({
                            abi: erc20Abi,
                            functionName: "transfer",
                            args: [
                                addressToWithdraw as `0x${string}`,
                                parseUnits(amount?.toString(), 6),
                            ],
                        }),
                    },
                ],
                true,
                amount
            );
            if (result && result?.isError) {
                setLoading(false);
                notify({
                    //@ts-ignore
                    title: result?.title,
                    //@ts-ignore
                    description: result?.description,
                });

                onClose();
                return;
            }

            notify({
                title: "Withdraw successful",
                description:
                    "You have successfully withdrawn funds from your smart account.",
                type: "Success",
            });
            onClose();
        } catch (e: any) {
            setLoading(false);
            console.error({ e });
            notify({
                title: "Withdraw failed",
                description: "Your withdraw request has failed.",
                type: "Error",
            });
            onClose();
        } finally {
            setLoading(false);

            onClose();
        }
    };

    const { balance } = useBalance({
        address,
        watch: true,
    });

    const formatBalance = useMemo(() => {
        if (address)
            return Number(balance?.formatted) > 2
                ? `Available: ${formatPrice(
                      Number(balance?.formatted) - 2 || 0,
                      {
                          currency: false,
                          decimals: 2,
                      }
                  )}`
                : `Available: ${formatPrice(Number(balance?.formatted) || 0, {
                      currency: false,
                      decimals: 2,
                  })}`;
        return "";
    }, [balance?.formatted, address]);

    const collateralPair = useMemo(() => {
        return {
            id: currentChain.symbol,
            value: currentChain.symbol,
            label: currentChain.symbol,
            image: getPairLogo(currentChain.symbol),
        };
    }, [currentChain.symbol]);

    const renderBalance = useMemo(() => {
        if (!address) return null;
        return (
            <Skeleton isLoading={!balance.value} width={80}>
                <Pressable
                    onPress={() => {
                        if (Number(balance?.formatted) > 2) {
                            setAmount(Number(balance?.formatted) - 2);
                            return;
                        }
                        setAmount(Number(balance?.formatted));
                    }}
                >
                    <Text
                        smaller
                        color={theme.color.rgba(theme.color.white, 0.7)}
                    >
                        {formatBalance}
                    </Text>
                </Pressable>
            </Skeleton>
        );
    }, [
        address,
        balance?.value,
        formatBalance,
        theme.color,
        balance?.formatted,
    ]);

    return (
        <Box
            style={{
                padding: theme.spacing.bigger,
                paddingTop: 0,
            }}
            gap={theme.spacing.huge}
        >
            <Text
                smaller
                color={theme.color.rgba(theme.color.white, 0.7)}
                lineHeight={theme.spacing.bigger}
            >
                Withdraw funds from your smart account to an external wallet.
            </Text>
            <Box gap={theme.spacing.tiny}>
                <Input
                    value={amount.toString()}
                    // @ts-ignore
                    name="amount"
                    label="Amount"
                    onChange={(amount) => {
                        setAmount(Number(amount));
                    }}
                    type="currency"
                    inputOptions={{
                        integerLimit: 7,
                        decimalLimit: 6,
                    }}
                    renderLabelRight={renderBalance}
                    placeholder="0.0"
                    style={{
                        color: theme.color.rgba(theme.color.white, 1),
                        fontFamily: theme.text.regularMono,
                        fontSize: theme.text.medium,
                    }}
                    currentOption={collateralPair}
                />
                <input
                    name="addressToWithdraw"
                    placeholder="Enter wallet address"
                    type="text"
                    value={addressToWithdraw}
                    onChange={(e) => {
                        setAddressToWithdraw(e.target.value as `0x${string}`);
                    }}
                    style={{
                        padding: "12px 10px",
                        opacity: 1,
                        zIndex: 10,
                        borderWidth: 1,
                        borderRadius: 8,
                        backgroundColor: "rgba(208, 219, 218, 0.05)",
                        color: "white",
                        borderColor: "rgba(208, 219, 218, 0.05)",
                        pointerEvents: "auto",
                        minHeight: 30,
                    }}
                />
                {/* <Input
                    value={addressToWithdraw}
                    onChangeText={(value: string) => {
                        setAddressToWithdraw(value as `0x${string}`);
                    }}
                    // @ts-ignore
                    name="addressToWithdraw"
                    label={"Enter wallet address"}
                    placeholder="0x...."
                    type="text"
                    style={{
                        fontFamily: theme.text.regularMono,
                        fontSize: theme.text.medium,
                        height: 150,
                    }}
                /> */}
            </Box>

            <Flex gap={theme.spacing.medium} justify="end">
                {/* //@ts-ignore */}
                <Button
                    text="Close"
                    primary={false}
                    onPress={onClose as () => void}
                />
                <Button
                    text="Withdraw"
                    primary
                    loading={loading}
                    onPress={handleWithdraw}
                    disabled={
                        loading ||
                        !amount ||
                        !addressToWithdraw?.length ||
                        amount > Number(balance?.formatted)
                    }
                />
            </Flex>
        </Box>
    );
};
