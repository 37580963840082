import ErrorBoundary from "@atoms/ErrorBoundary";
import { Box, Flex } from "@atoms/Flex";
import { IText, Text } from "@atoms/Text";
import { useMedia } from "@hooks/useMedia";
import { Skeleton } from "@molecules/Skeleton";
import { ITooltip, Tooltip } from "@molecules/Tooltip";
import {
    BIG_MAX_WIDTH,
    BIG_MIN_WIDTH,
    LIST_ITEM_HEIGHT,
    MAX_WIDTH,
    MIN_WIDTH,
} from "@screens/Trade/components/List/utils";
import { formatPrice } from "@utils";
import dynamic from "next/dynamic";
import { memo, useMemo } from "react";
import {
    Dimensions,
    FlatList,
    FlatListProps,
    ListRenderItem,
    Pressable,
    ViewStyle,
} from "react-native";
import { useTheme } from "styled-components/native";

const InfinityLoader = dynamic(
    () => import("@atoms/LoadingIndicator").then((mod) => mod.InfinityLoader),
    {
        ssr: true,
    }
);

interface IList<T> extends FlatListProps<T> {
    isLoading: boolean;
    data: any[];
    emptyText?: string;
    renderItem: ListRenderItem<T>;
    renderHeader?: FlatListProps<any>["ListHeaderComponent"];
    onEndReached?: () => void;
    keyExtractor?: (item: T, index: number) => string;
    renderEmpty?: JSX.Element | JSX.Element[];
    onEndReachedThreshold?: number;
    contentContainerStyle?: ViewStyle;
    hiddenCount?: number;
}

export const List = memo(
    ({
        isLoading,
        data,
        emptyText,
        renderItem,
        renderEmpty,
        onEndReached,
        onEndReachedThreshold,
        contentContainerStyle,
        renderHeader,
        keyExtractor,
        ...props
    }: IList<any>) => {
        const renderCellComponent = useMemo(
            () => (props: any) => {
                return (
                    // @ts-ignore
                    <ErrorBoundary
                        style={{
                            height: LIST_ITEM_HEIGHT,
                            flexDirection: "row",
                            flex: null,
                        }}
                    >
                        <Box
                            {...props}
                            style={{
                                zIndex: data?.length - props?.index,
                            }}
                        >
                            {props?.children}
                        </Box>
                    </ErrorBoundary>
                );
            },
            [data?.length]
        );

        return (
            <FlatList
                data={data}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                onEndReached={onEndReached}
                onEndReachedThreshold={onEndReachedThreshold || 0.5}
                style={{ flex: 1, width: "100%" }}
                contentContainerStyle={{
                    ...(!data?.length || isLoading ? { flex: 1 } : {}),
                    ...contentContainerStyle,
                }}
                // CellRendererComponent={renderCellComponent}
                ListHeaderComponent={renderHeader}
                initialNumToRender={20}
                ListEmptyComponent={
                    <ListEmpty loading={isLoading} text={emptyText || "None"}>
                        {renderEmpty}
                    </ListEmpty>
                }
                {...props}
            />
        );
    }
);

export const ListItemCell = memo(
    ({
        text,
        textProps,
        renderText,
        secondaryText,
        secondaryTextProps,
        renderSecondaryText,
        style,
        children,
        isFirst,
        isLast,
        isLoading,
        tooltip,
        contentStyle,
        onPress,
    }: {
        text?: string | number | null;
        textProps?: IText;
        secondaryText?: string | number | null;
        secondaryTextProps?: IText;
        renderText?: JSX.Element | JSX.Element[];
        renderSecondaryText?: JSX.Element | JSX.Element[];
        contentStyle?: ViewStyle;

        children?: JSX.Element | JSX.Element[];
        isFirst?: boolean;
        isLast?: boolean;
        isLoading?: boolean;
        tooltip?: ITooltip;
        style?: ViewStyle;
        onPress?: () => void;
    }) => {
        const theme = useTheme();
        const media = useMedia();

        const cellStyle: ViewStyle = {
            height: "100%",
            justifyContent: "center",
            position: "relative",
            minWidth: MIN_WIDTH,
            maxWidth: isLast ? undefined : MAX_WIDTH,
            flex: 1,
            paddingLeft: isFirst ? theme.spacing.bigger : 0,
            paddingRight: isLast ? theme.spacing.bigger : 0,
            ...style,
        };

        const renderContent = useMemo(() => {
            if (children) return children;

            return (
                <Box
                    gap={theme.spacing.tiny}
                    style={{ justifyContent: "center", ...contentStyle }}
                >
                    {renderText ? (
                        renderText
                    ) : (
                        <Text mono smaller semiBold {...textProps}>
                            {typeof text === "string"
                                ? text
                                : `${formatPrice(text, {
                                      significant: 6,
                                      //   currency: true,
                                  })}`}
                        </Text>
                    )}

                    {renderSecondaryText ? (
                        renderSecondaryText
                    ) : secondaryText ? (
                        <Text
                            mono
                            smaller
                            color={theme.color.rgba(theme.color.white, 0.4)}
                            {...secondaryTextProps}
                        >
                            {typeof secondaryText === "string"
                                ? secondaryText
                                : `${formatPrice(secondaryText, {
                                      significant: 6,
                                      currency: true,
                                  })}`}
                        </Text>
                    ) : null}
                </Box>
            );
        }, [
            children,
            theme.spacing.tiny,
            theme.color,
            contentStyle,
            renderText,
            textProps,
            text,
            renderSecondaryText,
            secondaryText,
            secondaryTextProps,
        ]);

        return (
            <Box style={[{ justifyContent: "center" }, cellStyle]}>
                <Skeleton
                    secondary={
                        secondaryText != null || renderSecondaryText != null
                            ? {
                                  width: 50,
                                  height: 12,
                              }
                            : undefined
                    }
                    style={cellStyle}
                    isLoading={!!isLoading && !children}
                >
                    <Pressable
                        onPress={onPress}
                        disabled={!onPress}
                        style={{
                            height: "100%",
                            justifyContent: "center",
                            flex: 1,
                        }}
                    >
                        {tooltip ? (
                            <Tooltip
                                {...tooltip}
                                position="top-left"
                                pointerEvents="none"
                                style={{
                                    height: "100%",
                                    flex: 1,
                                    justifyContent: "center",
                                }}
                            >
                                {renderContent}
                            </Tooltip>
                        ) : (
                            renderContent
                        )}
                    </Pressable>
                </Skeleton>
            </Box>
        );
    }
);

export const ListEmpty = ({
    loading,
    children,
}: {
    loading: boolean;
    text?: string;
    children?: JSX.Element | JSX.Element[];
}) => {
    const theme = useTheme();
    const media = useMedia();

    if (loading)
        return (
            <Box align="center" justify="center" flex={1}>
                <InfinityLoader />
            </Box>
        );

    return (
        <Box
            flex={1}
            align="center"
            justify="center"
            style={{
                padding: theme.spacing.small,
                width: media.isMobile ? Dimensions.get("window").width : "100%",
            }}
        >
            {children}
        </Box>
    );
};
