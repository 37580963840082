import { safe, walletConnect } from "@wagmi/connectors";
import { createClient, http } from "viem";
import { cookieStorage, createStorage } from "wagmi";
import { createConfig } from "@privy-io/wagmi";
import { arbitrum, arbitrumSepolia } from "wagmi/chains";

export const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_ID ?? "";

if (!projectId) throw new Error("Project ID is not defined");

export const metadata = {
    name: "Ostium",
    description: "Ostium",
    url: "https://ostium.app",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const qrModalOptions = {
    themeVariables: {
        "--wcm-z-index": "10004",
    },
};

const chains = [arbitrum, arbitrumSepolia] as const;

export const wagmiConfig = createConfig({
    chains,
    ssr: true,
    connectors: [safe()],
    storage: createStorage({
        storage: cookieStorage,
    }),
    client: ({ chain }) => {
        return createClient({
            chain,
            transport: http(),
        });
    },
});
