import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Image } from "@atoms/Image";
import { useMedia } from "@hooks/useMedia";
import { Button } from "@molecules/Button";
import {
    NAVIGATION_MORE_LINKS,
    Navigation,
    NavigationLinkType,
    NavigationMobile,
} from "@organisms/Header/Navigation";
import { UserSettings } from "@screens/components/Header/User";
import { useRouting } from "expo-next-react-navigation";
import { memo, useCallback, useState } from "react";
import { Link } from "solito/link";
import { useTheme } from "styled-components/native";
import { useDisconnect } from "wagmi";
import { BlurView } from "@atoms/BlurView";
import { Portal } from "@atoms/Portal";
import { NavigationLink } from "@organisms/Header/Link";
import { Pressable } from "react-native";
import { NewVersionButton } from "./Buttons/NewVersionButton";
import { Socials } from "./Socials";
import { useAppContext } from "@contexts/app";
import Modal from "@organisms/Modal";
import { Referrals } from "@screens/ETHCC/Derby/Referrals";
import { AppAlert } from "@screens/Trade/components/Banners/AppAlert";
import { useFundWallet, usePrivy } from "@privy-io/react-auth";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { WithdrawModal } from "./User/Sidebar/WithdrawModal";
import { SmartWalletAlert } from "@screens/Trade/components/Banners/SmartWalletAlert";

export const HEADER_HEIGHT = 60;
export const CTA_HEIGHT = 30;

export const NAVIGATION_LINKS: NavigationLinkType[] = [
    {
        text: "Explore",
        url: "/explore",
    },
    {
        text: "Trade",
        url: "/trade",
    },
    {
        text: "Strategies",
        url: "/strategies",
    },
    {
        text: "Vault",
        url: "/vault",
    },
    {
        text: "Leaderboard",
        url: "/leaderboard",
    },
    {
        text: "Portfolio",
        url: "/portfolio",
    },
    // {
    //     text: "Derby",
    //     url: "/derby",
    // }
    {
        text: "Join",
        items: NAVIGATION_MORE_LINKS,
    },
];

const Header = memo(({ simple }: { simple?: boolean }) => {
    const media = useMedia();
    const theme = useTheme();
    const { navigate } = useRouting();
    const { address } = useOstiumAccount();
    const { disconnect } = useDisconnect();
    const { user } = usePrivy();
    const { fundWallet } = useFundWallet();
    const [isReferralModalVisible, setReferralModalVisible] = useState(false);
    const {
        isSmartAccountEnabled,
        toggleSmartAccount,
        setIsWithdrawModalVisible,
        isWithdrawModalVisible,
    } = useAppContext();
    const { logoutSmartAccount, sendSmartWalletOrder } =
        usePrivySmartWalletContext();

    const toggleReferralModal = useCallback(() => {
        setReferralModalVisible(!isReferralModalVisible);
    }, [isReferralModalVisible]);

    const openCompetition = () => {
        navigate({
            routeName: "Contest",
            web: {
                as: "/contest",
                pathname: "/contest",
            },
        });
    };

    const onDisconnect = () => {
        disconnect();
        logoutSmartAccount();
    };

    const { isMobile } = useMedia();

    return (
        // <Portal>
        <Box
            style={{
                position: "fixed",
                left: 0,
                right: 0,
                zIndex: 1,
                top: 0,
            }}
            align="center"
        >
            <Box
                style={{
                    width: "100%",
                    zIndex: 10,
                }}
            >
                <BlurView
                    // intensity={100}
                    style={{
                        zIndex: 100,
                    }}
                // black
                >
                    <AppAlert />
                    <SmartWalletAlert />
                    <Flex
                        align="center"
                        justify="space-between"
                        style={{
                            paddingLeft: media.isDesktopSmall
                                ? 0
                                : theme.spacing.big,
                            paddingRight: media.isDesktopSmall
                                ? 0
                                : theme.spacing.smaller,

                            height: HEADER_HEIGHT,
                        }}
                    >
                        <Flex flex={1} style={{ height: "100%" }}>
                            <Link
                                href="/"
                                suppressHydrationWarning
                                prefetch={false}
                            >
                                <Box
                                    style={{
                                        position: "relative",
                                        height: "100%",
                                        paddingRight: theme.spacing.big,
                                        paddingLeft: media.isDesktopSmall
                                            ? theme.spacing.big
                                            : 0,
                                    }}
                                    justify="center"
                                >
                                    {media.isDesktopSmall ? (
                                        <Image
                                            alt="Ostium Logo"
                                            source="/assets/logo-square.svg"
                                            width={24}
                                            height={24}
                                            priority
                                            selectable={false}
                                        />
                                    ) : (
                                        <Image
                                            alt="Ostium Logo"
                                            source={"/assets/logo.svg"}
                                            width={123}
                                            height={24}
                                            selectable={false}
                                            priority
                                        />
                                    )}
                                </Box>
                            </Link>

                            {!media.isDesktopSmall && !simple ? (
                                <Navigation items={NAVIGATION_LINKS} />
                            ) : null}
                        </Flex>

                        {!simple ? (
                            <Flex
                                align="center"
                                justify="flex-end"
                                style={{
                                    height: "100%",
                                    zIndex: 10,
                                }}
                                gap={theme.spacing.smaller}
                            >
                                {/* {media.isMobile ? null : (
                                    <Button
                                        primary
                                        small
                                        stroke
                                        // noBorder
                                        style={{ height: 32 }}
                                        onPress={openCompetition}
                                        text={"Competition"}
                                    />
                                )} */}

                                <NewVersionButton />
                                {/* {address ? (
                                        <Button
                                            primary
                                            small
                                            stroke
                                            style={{ height: 36 }}
                                            onPress={openCompetition}
                                            text={"Competition"}
                                        />
                                    ) : null} */}

                                {address && !isMobile ? (
                                    <>
                                        {user &&
                                            user.google &&
                                            user.google.email ? (
                                            <Button
                                                primary
                                                small
                                                // stroke
                                                noBorder
                                                style={{ height: 32 }}
                                                onPress={() => {
                                                    fundWallet(address);
                                                }}
                                                text={"Fund Account"}
                                            />
                                        ) : isSmartAccountEnabled ? (
                                            <Button
                                                primary
                                                small
                                                // stroke
                                                noBorder
                                                style={{ height: 32 }}
                                                onPress={() => {
                                                    fundWallet(address);
                                                }}
                                                text={"Fund Account"}
                                            />
                                        ) : (
                                            <Button
                                                primary
                                                small
                                                // stroke
                                                noBorder
                                                style={{ height: 32 }}
                                                onPress={() => {
                                                    toggleSmartAccount();
                                                }}
                                                text={"Enable Account"}
                                            />
                                        )}

                                        <Button
                                            small
                                            primary
                                            noBorder
                                            stroke
                                            text="Referrals"
                                            onPress={toggleReferralModal}
                                        />
                                    </>
                                ) : null}

                                <UserSettings />

                                <Modal
                                    isVisible={isWithdrawModalVisible}
                                    title="Withdraw Funds"
                                >
                                    <WithdrawModal
                                        onClose={() =>
                                            setIsWithdrawModalVisible(false)
                                        }
                                        sendSmartWalletOrder={
                                            sendSmartWalletOrder
                                        }
                                    />
                                </Modal>
                                {media.isDesktopSmall ? (
                                    <Box
                                        style={{
                                            height: "100%",
                                            marginRight: theme.spacing.smaller,
                                        }}
                                    >
                                        <MobileMenuButton
                                            items={NAVIGATION_LINKS}
                                        />
                                    </Box>
                                ) : null}
                            </Flex>
                        ) : (
                            <Flex
                                style={{
                                    height: "100%",
                                    paddingRight: media.isDesktopSmall
                                        ? theme.spacing.bigger
                                        : theme.spacing.smallest,
                                }}
                                align="center"
                                gap={theme.spacing.smaller}
                            >
                                <NavigationLink
                                    text="Links"
                                    items={NAVIGATION_MORE_LINKS}
                                />
                                {address ? (
                                    <Button
                                        iconProps={{
                                            name: "disconnect",
                                        }}
                                        small
                                        primary
                                        stroke
                                        onPress={onDisconnect}
                                        text="Disconnect"
                                        style={{
                                            width: media.isDesktopSmall
                                                ? 32
                                                : undefined,
                                            paddingHorizontal: 0,
                                        }}
                                    />
                                ) : null}
                            </Flex>
                        )}
                    </Flex>
                </BlurView>
            </Box>
            <Modal
                isVisible={isReferralModalVisible}
                onClose={toggleReferralModal}
                title={"Earn Referral Rewards"}
            >
                <Referrals onClose={toggleReferralModal} />
            </Modal>
        </Box>
    );
});

export const MobileMenuButton = ({
    items,
}: {
    items: NavigationLinkType[];
}) => {
    const theme = useTheme();
    const { isCTAActive } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <Pressable
                onPress={toggleOpen}
                style={{
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <Box
                    style={{
                        paddingHorizontal: theme.spacing.smaller,
                        height: 32,
                        // borderWidth: 1,
                        // borderColor: theme.color.rgba(
                        //     theme.color.white,
                        //     isOpen ? 0.1 : 0.05
                        // ),
                        borderRadius: theme.radius.medium,
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            isOpen ? 0.2 : 0.1
                        ),
                    }}
                    align="center"
                    justify="center"
                >
                    <Icon
                        name="burger"
                        color={theme.color.rgba(theme.color.white, 0.4)}
                    />
                </Box>
            </Pressable>
            <Portal>
                <Box
                    style={{
                        position: "fixed",
                        top: HEADER_HEIGHT + (isCTAActive ? CTA_HEIGHT : 0),
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: isOpen ? 1 : -1,
                        opacity: isOpen ? 1 : 0,
                        backgroundColor: theme.color.black,
                    }}
                    justify="space-between"
                >
                    <NavigationMobile items={items} onToggle={toggleOpen} />
                    <Box
                        style={{
                            padding: theme.spacing.tiny,
                        }}
                    >
                        <Socials big />
                    </Box>
                </Box>
            </Portal>
        </>
    );
};

export default Header;
