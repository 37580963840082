import { useCallback } from "react";
import { useAppContext } from "@contexts/app";
import { useNotificationContext } from "@contexts/notification";
import { useWeb3Context } from "@contexts/web3";

import { EVENT_NAMES } from "constants/events";
import useAnalytics from "./useAnalytics";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { encodeFunctionData } from "viem";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { OstiumTrading__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumTrading__factory";

const EOA = "0xE2aB1440e4C724005cc312FBDADa9BaAC335ba61";
const EMPTY_ADDRESS = "0x0000000000000000000000000000000000000000";

export const useDelegation = () => {
    const { address } = useOstiumAccount();
    const { settings, updateSetting, isSmartAccountEnabled } = useAppContext();
    const { tradingContract, currentChain } = useWeb3Context();
    const { notify } = useNotificationContext();
    const { trackEvent } = useAnalytics();
    const { sendSmartWalletOrder } = usePrivySmartWalletContext();

    const trackDelegationEvents = useCallback(
        (eventName: string, data: any) => trackEvent(eventName, data),
        [trackEvent]
    );

    const setDelegationEnabled = useCallback(
        (enabled: boolean) => updateSetting({ ...settings, delegation: enabled }),
        [settings, updateSetting]
    );

    const handleDelegationError = useCallback(
        (message: string, title = "Delegation Error") => {
            notify({ title, type: "Error", description: message });
            return false;
        },
        [notify]
    );

    const getDelegations = useCallback(async () => {
        if (!address || !tradingContract) return null;
        try {
            const delegation = await tradingContract.delegations(address);
            return delegation;
        } catch {
            return null;
        }
    }, [address, tradingContract]);

    const callSetDelegate = useCallback(async () => {
        return isSmartAccountEnabled
            ? sendSmartWalletOrder([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "setDelegate",
                        args: [EOA],
                    }),
                },
            ])
            : tradingContract?.setDelegate(EOA);
    }, [isSmartAccountEnabled, currentChain.contracts.trading, tradingContract, sendSmartWalletOrder]);

    const callRemoveDelegate = useCallback(async () => {
        return isSmartAccountEnabled
            ? sendSmartWalletOrder([
                {
                    to: currentChain.contracts.trading,
                    data: encodeFunctionData({
                        abi: OstiumTrading__factory.abi,
                        functionName: "removeDelegate",
                    }),
                },
            ])
            : tradingContract?.removeDelegate();
    }, [isSmartAccountEnabled, currentChain.contracts.trading, tradingContract, sendSmartWalletOrder]);

    const checkDelegation = useCallback(async () => {
        const delegation = await getDelegations();
        if (delegation === EMPTY_ADDRESS || !delegation) {
            notify({
                title: "No delegations",
                autoClose: 3000,
                type: "Info",
                description: "You have no delegations.",
            });
            setDelegationEnabled(false);
            return null;
        }
        if (delegation === EOA) setDelegationEnabled(true);
        return delegation;
    }, [getDelegations, notify, setDelegationEnabled]);

    const setDelegation = useCallback(async (otherData?: any) => {
        trackDelegationEvents(EVENT_NAMES.CHECK_DELEGATION, otherData);
        try {
            const result = await callSetDelegate();
            // notify({
            //     title: "Delegating access...",
            //     type: "Info",
            //     description: isSmartAccountEnabled
            //         ? "You will do this once to allow trades to be placed"
            //         : "You will do this once in Metamask to allow trades to be placed.",
            // });

            if (result && (await getDelegations()) === EOA) {
                notify({
                    title: "Delegated Access",
                    type: "Success",
                    description: "You have delegated access.",
                });
                setDelegationEnabled(true);
                return true;
            }
        } catch (err) {
            setDelegationEnabled(false);
            return handleDelegationError("Failed to set delegation. Please try again.");
        }
        setDelegationEnabled(false);
        return false;
    }, [trackDelegationEvents, notify, setDelegationEnabled, callSetDelegate, getDelegations, handleDelegationError]);

    const removeDelegation = useCallback(async () => {
        const currentDelegation = await getDelegations();
        if (!currentDelegation || currentDelegation === EMPTY_ADDRESS) {
            notify({
                title: "No delegations",
                autoClose: 3000,
                type: "Info",
                description: "You have no delegations.",
            });
            setDelegationEnabled(false);
            return false;
        }

        try {
            const result = await callRemoveDelegate();
            if (result) {
                notify({
                    title: "Delegation Removed",
                    type: "Info",
                    description: "You have no delegations.",
                });
                setDelegationEnabled(false);
                return true;
            }
        } catch (err) {
            return handleDelegationError("Failed to remove delegation.");
        }
        return false;
    }, [callRemoveDelegate, getDelegations, notify, handleDelegationError, setDelegationEnabled]);

    return {
        toggleEnabled: settings?.delegation,
        setDelegation,
        checkDelegation,
        removeDelegation,
        setDelegationEnabled,
    };
};
