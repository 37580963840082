import { useAppContext } from "@contexts/app";
import { usePrivySmartWalletContext } from "@contexts/privySmartWallet";
import { usePrivy } from "@privy-io/react-auth";
import { useEffect } from "react";
import { useAccount } from "wagmi";

export function useOstiumAccount() {
    const { isSmartAccountEnabled } = useAppContext();
    const { address: wagmiAddress, isConnected, isDisconnected } = useAccount();
    const { smartAccountAddress, embeddedWalletAddress } =
        usePrivySmartWalletContext();
    const address = isSmartAccountEnabled ? smartAccountAddress : wagmiAddress;

    return {
        address: address as `0x${string}` | undefined,
        smartAccountAddress,
        wagmiAddress,
        embeddedWalletAddress,
    };
}
