import { Avatar } from "@atoms/Avatar";
import { BoxContainer } from "@atoms/BoxContainer";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useOstiumAccount } from "@contexts/hooks/useOstiumAccount";
import { PriceProvider, usePriceContext } from "@contexts/price";
import { useWeb3Context } from "@contexts/web3";
import { gql, useQuery } from "@hooks/useApollo";
import { useMedia } from "@hooks/useMedia";
import { Button } from "@molecules/Button";
import { Statistic } from "@molecules/Statistic";
import { Tooltip } from "@molecules/Tooltip";
import { GET_USER } from "@screens/Portfolio/Positions/PortfolioStatistics";
import { getTradePNL } from "@screens/Trade/utils";
import { HEADER_HEIGHT } from "@screens/components/Header";
import { formatAddress, formatLargeNumber, formatPrice } from "@utils";
import { User } from "gql/graphql";
import Link from "next/link";
import { memo, useMemo, useState } from "react";
import { Linking, Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";
import { useBlockNumber } from "wagmi";

export const Overview = memo(({ address }: { address?: `0x${string}` }) => {
    const theme = useTheme();
    const { settings } = useAppContext();
    // const today = moment().format("YYYY-MM-DD");
    // const currentDayFormatted = useMemo(() => {
    //     return currentDay && moment().diff(currentDay?.dateString, "days") > 0
    //         ? moment(currentDay?.dateString).format("d MMM yyyy")
    //         : "Today";
    // }, [currentDay]);
    const [currentDay, setCurrentDay] = useState();
    const [markedDates, setMarkedDates] = useState({});

    const [isActive, setIsActive] = useState(false);

    const toggleActive = () => {
        setIsActive(!isActive);
    };

    const onDayPress = (day: any) => {
        setCurrentDay(day);
        setMarkedDates({
            [day.dateString]: {
                selected: true,
                color: theme.color.primary,
            },
        });
        toggleActive();
    };

    return (
        <Flex gap={settings.theme.boxed ? theme.spacing.smaller : 0}>
            <BoxContainer
                style={{
                    flex: 1,
                    borderWidth: 0,
                }}
            >
                <Box
                    style={
                        {
                            // borderBottomWidth: 1,
                            // borderColor: theme.color.rgba(theme.color.white, 0.05),
                        }
                    }
                >
                    <PriceProvider>
                        <UserStatistics address={address} />
                    </PriceProvider>
                </Box>
                {/* <Box
                    style={{
                        height: 300,
                        padding: theme.spacing.bigger,
                        position: "relative",
                    }}
                >
                    <PNLGraph />
                </Box> */}
            </BoxContainer>
        </Flex>
    );
});

const UserStatistics = memo(({ address }: { address?: `0x${string}` }) => {
    const { currentChain } = useWeb3Context();
    const theme = useTheme();
    const media = useMedia();
    const { address: myAddress } = useOstiumAccount();
    const { initial, toggleConnectModal } = useAppContext();
    const { prices } = usePriceContext();

    const { data: blockNumber } = useBlockNumber({
        watch: true,
    });

    const [user, setUser] = useState<User>(initial?.user as User);
    const isMine =
        myAddress != null && address != null && myAddress === address;

    useQuery(GET_USER, {
        variables: {
            id: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 10000,
        onCompleted: (data) => {
            setUser(data?.user);
        },
    });

    const { data: tradesData } = useQuery(GET_USER_TRADES, {
        variables: {
            trader: address?.toLowerCase(),
        },
        skip: !address,
        pollInterval: 10000,
    });
    const formattedAddress = useMemo(() => {
        return formatAddress(address);
    }, [address]);

    const pnl = useMemo(
        () =>
            tradesData?.trades?.reduce(
                (acc: number, trade: any) => {
                    const marketPrice = prices?.get(trade.pair.from);
                    const data = getTradePNL(trade, marketPrice, blockNumber);

                    return acc + data.netPNL;
                },
                user?.totalPnL ? Number(formatUnits(user?.totalPnL, 6)) : 0
            ),
        [blockNumber, prices, tradesData?.trades, user?.totalPnL]
    );

    const onExplorer = () => {
        Linking.openURL(`${currentChain?.explorer}address/${address}`);
    };

    const renderStatistics = useMemo(() => {
        return (
            <Box
                style={{
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: media.isMobile ? theme.spacing.bigger : 0,
                }}
            >
                <Statistic
                    label="Profit & Loss"
                    red={pnl < 0}
                    green={pnl > 0}
                    loading={!pnl}
                    value={`${formatPrice(pnl, {
                        decimals: 2,
                        currency: true,
                    })}`}
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        borderRightWidth: 1,
                        borderLeftWidth: 1,
                        borderColor: theme.color.rgba(theme.color.white, 0.05),
                        alignItems: "flex-end",
                    }}
                />

                <Statistic
                    label="Total Volume"
                    loading={!user?.totalVolume}
                    value={
                        user?.totalVolume
                            ? `${formatLargeNumber(
                                Number(formatUnits(user?.totalVolume, 6))
                            )}`
                            : 0
                    }
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        borderRightWidth: 1,
                        borderColor: theme.color.rgba(theme.color.white, 0.05),
                        alignItems: "flex-end",
                    }}
                />

                <Statistic
                    label="Gain vs Loss"
                    loading={!user?.totalProfitTrades}
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        alignItems: "flex-end",
                    }}
                >
                    <Flex align="flex-end">
                        <Text mono bold color={theme.color.green}>
                            {user?.totalProfitTrades}
                        </Text>
                        <Text
                            mono
                            bold
                            color={theme.color.rgba(theme.color.white, 0.4)}
                        >
                            {" - "}
                        </Text>
                        <Text mono bold color={theme.color.red}>
                            {user?.totalLossTrades}
                        </Text>
                    </Flex>
                </Statistic>
            </Box>
        );
    }, [
        media.isMobile,
        pnl,
        theme.color,
        theme.spacing.bigger,
        user?.totalLossTrades,
        user?.totalProfitTrades,
        user?.totalVolume,
    ]);

    return (
        <Flex
            style={
                media.isMobile
                    ? {
                        flexDirection: "column",
                    }
                    : {
                        height: HEADER_HEIGHT + theme.spacing.biggest,
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }
            }
        >
            <Flex align="center">
                {address ? (
                    <Link
                        href={`/portfolio/preview?address=${address}`}
                        suppressHydrationWarning
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            gap: theme.spacing.big,
                            padding: theme.spacing.bigger,
                        }}
                    >
                        <Avatar size={36} string={address} />
                        <Statistic
                            label="Account"
                            value={formattedAddress}
                            valueProps={{
                                primary: true,
                            }}
                        />
                        {/* <Box gap={theme.spacing.smaller}>
                        <Text semiBold small>
                            Account
                        </Text>
                        <Text semiBold smaller primary>
                            {formattedAddress}
                        </Text>
                    </Box> */}
                    </Link>
                ) : (
                    <Pressable
                        onPress={toggleConnectModal}
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            gap: theme.spacing.big,
                            padding: theme.spacing.bigger,
                        }}
                    >
                        <Avatar size={36} string={address} />
                        <Statistic
                            label="Account"
                            value={"Not Connected"}
                            valueProps={{
                                primary: true,
                            }}
                        />
                    </Pressable>
                )}

                {media.isMobile && address ? (
                    <Box
                        align="center"
                        justify="center"
                        style={{
                            paddingRight: theme.spacing.bigger,
                        }}
                    >
                        <Button
                            primary
                            small
                            iconRight
                            stroke
                            iconProps={{ name: "external" }}
                            text={`View on ${currentChain.name === "sepolia"
                                ? "Arbiscan"
                                : "Arbiscan"
                                }`}
                            onPress={onExplorer}
                        />
                    </Box>
                ) : (
                    renderStatistics
                )}
            </Flex>

            {/* <LongShortGraph /> */}
            {media.isMobile ? (
                renderStatistics
            ) : address ? (
                <Box
                    align="center"
                    justify="center"
                    style={{
                        paddingRight: theme.spacing.bigger,
                    }}
                >
                    <Button
                        primary
                        small
                        iconRight
                        stroke
                        iconProps={{ name: "external" }}
                        text={`View on ${currentChain.name === "sepolia"
                            ? "Arbiscan"
                            : "Arbiscan"
                            }`}
                        onPress={onExplorer}
                    />
                </Box>
            ) : null}
        </Flex>
    );
});

export const GET_USER_TRADES = gql`
    query getUserTrades($trader: Bytes!) @api(name: subgraph) {
        trades(where: { isOpen: true, trader: $trader }) {
            tradeNotional
            tradeType
            openPrice
            isBuy
            notional
            collateral
            leverage
            funding
            rollover
            timestamp
            pair {
                id
                from
                to
                feed
                spreadP
                accRollover
                accFundingLong
                accFundingShort
                curFundingLong
                curFundingShort
                makerMaxLeverage
                lastFundingRate
                lastFundingVelocity
                maxFundingFeePerBlock
                lastFundingBlock
                lastFundingTime
                lastRolloverBlock
                rolloverFeePerBlock
                longOI
                shortOI
                tradeSizeRef
                group {
                    name
                }
            }
        }
    }
`;
