import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { State } from "wagmi";
import { WagmiProvider as WWagmiProvider } from "@privy-io/wagmi";

import { wagmiConfig } from "./wagmiConfig";

export const WagmiProvider = ({
    children,
    initialState,
}: {
    children: React.ReactNode;
    initialState?: State;
}) => {
    const [queryClient] = useState(() => new QueryClient());

    return (
        <QueryClientProvider client={queryClient}>
            <WWagmiProvider config={wagmiConfig} initialState={initialState}>
                {children}
            </WWagmiProvider>
        </QueryClientProvider>
    );
};
