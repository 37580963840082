"use client";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useAccount } from "wagmi";
import { entryPoint06Address } from "viem/account-abstraction";
import { erc20Abi, formatUnits, http } from "viem";
import { SmartAccountClient, createSmartAccountClient } from "permissionless";
import {
    createPimlicoClient,
    PimlicoClient,
} from "permissionless/clients/pimlico";
import { WagmiProvider, createConfig } from "@privy-io/wagmi";
import { arbitrumSepolia, arbitrum } from "viem/chains";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { usePrivy, useSignMessage, useWallets } from "@privy-io/react-auth";
import { useSetActiveWallet } from "@privy-io/wagmi";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import { SafeSmartAccountImplementation } from "permissionless/accounts";
import { useWeb3Context } from "./web3";
import { useAppContext } from "./app";
import { useBalance } from "@hooks/useBalance";
import { gql } from "@apollo/client/core";
import { MESSAGE } from "@screens/Authentication/AcceptTerms";

type SmartWalletClientType = ReturnType<typeof useSmartWallets>["client"];

interface IPrivySmartWallet {
    smartAccountAddress?: string;
    embeddedWalletAddress?: string;
    smartAccountClient?: SmartWalletClientType | null;
    pimlicoClient?: any;
    prepareUserOperation: (
        erc20token: `0x${string}`,
        calls: any
    ) => Promise<{ maxCostInToken: bigint; paymaster: string }>;
    sendSmartWalletOrder: (
        calls: any,
        isDebit?: boolean,
        debitAmount?: number
    ) => Promise<{
        hash: `0x${string}` | undefined;
        isError?: boolean;
        title?: string;
        description?: string;
    }>;
    logoutSmartAccount: () => Promise<void>;
    signFTUEMessage: (walletAddress: string) => void;
}
const PrivySmartWalletContext = createContext({} as IPrivySmartWallet);

const usePrivySmartWallet = () => {
    const { login, ready, authenticated, user, logout } = usePrivy();
    const { isConnected, ...account } = useAccount();
    const { wallets, ready: walletsReady } = useWallets();
    const [smartWallet, setSmartWallet] = useState<any>(undefined);
    const [embeddedWallet, setEmbeddedWallet] = useState<any>(undefined);
    const { currentChain } = useWeb3Context();
    const { client } = useSmartWallets();
    const { signMessage } = useSignMessage();

    const [smartAccountClient, setSmartAccountClient] =
        useState<SmartWalletClientType | null>(null);
    const [pimlicoClient, setPimlicoClient] = useState<PimlicoClient | null>(
        null
    );
    const { balance } = useBalance({
        address: smartWallet?.address,
        watch: true,
    });

    useEffect(() => {
        const pimlicoRpcUrl = `https://api.pimlico.io/v2/${currentChain?.id}/rpc?apikey=${process.env.NEXT_PUBLIC_BUNDLER_KEY}`;

        const pimlicoClient = createPimlicoClient({
            transport: http(pimlicoRpcUrl),
            entryPoint: {
                address: entryPoint06Address,
                version: "0.6",
            },
            chain:
                currentChain?.id === arbitrum.id ? arbitrum : arbitrumSepolia,
        });

        setPimlicoClient(pimlicoClient);
    }, [currentChain]);

    useEffect(() => {
        if (user) {
            const smartWallet = user.linkedAccounts.find(
                (account) => account.type === "smart_wallet"
            );
            setSmartWallet(smartWallet);

            const embeddedWallet = user.linkedAccounts.find(
                //@ts-ignore
                (account) => account.connectorType === "embedded"
            );
            setEmbeddedWallet(embeddedWallet);

            if (!user.hasAcceptedTerms) {
            }
        }
    }, [user]);

    useEffect(() => {
        (async () => {
            if (client) {
                try {
                    await client?.switchChain({
                        id: currentChain?.id,
                    });
                } catch (e) {}
                const chain = await client.getChainId();
                setSmartAccountClient(client);
            }
        })();
    }, [client, currentChain?.id]);
    console.log("user", user);
    const logoutSmartAccount = useCallback(async () => {
        window?.localStorage?.setItem("isSmartAccountEnabled", "false");
        await logout();
        setSmartAccountClient(null);
        setSmartWallet(null);
    }, [logout]);

    const prepareUserOperation = useCallback(
        async (erc20token: `0x${string}`, calls: any) => {
            //@ts-ignore
            const quotes = await pimlicoClient?.getTokenQuotes({
                tokens: [erc20token],
            });
            //@ts-ignore
            const { postOpGas, exchangeRate, paymaster } = quotes[0];

            const userOperation =
                await smartAccountClient?.prepareUserOperation({
                    calls,
                });

            const userOperationMaxGas =
                (userOperation?.preVerificationGas || BigInt(0)) +
                (userOperation?.callGasLimit || BigInt(0)) +
                (userOperation?.verificationGasLimit || BigInt(0)) +
                (userOperation?.paymasterPostOpGasLimit || BigInt(0)) +
                (userOperation?.paymasterVerificationGasLimit || BigInt(0));

            const userOperationMaxCost =
                userOperationMaxGas *
                (userOperation?.maxFeePerGas || BigInt(0));

            // using formula here https://github.com/pimlicolabs/singleton-paymaster/blob/main/src/base/BaseSingletonPaymaster.sol#L334-L341
            const maxCostInToken =
                ((userOperationMaxCost +
                    postOpGas * (userOperation?.maxFeePerGas || BigInt(0))) *
                    exchangeRate) /
                BigInt(1e18);

            return { maxCostInToken, paymaster };
        },
        [smartAccountClient, pimlicoClient]
    );

    const getPaymaster = useCallback(
        async (erc20token: `0x${string}`, calls: any) => {
            //@ts-ignore
            const quotes = await pimlicoClient?.getTokenQuotes({
                tokens: [erc20token],
            });
            //@ts-ignore
            const { paymaster } = quotes[0];

            return { paymaster };
        },
        [pimlicoClient]
    );

    const signFTUEMessage = useCallback(
        async (walletAddress: string) => {
            if (!user || !walletAddress) return;
            const signedMessage = await signMessage({ message: MESSAGE });

            const params = new URLSearchParams({
                address:
                    walletAddress || (user?.smartWallet?.address as string),
                message: MESSAGE as string,
                signedMessage: signedMessage.signature as string,
            });

            fetch(`/api/user/accept-terms`, {
                method: "POST",
                body: params,
            });
        },
        [user, signMessage]
    );

    const sendSmartWalletOrder = useCallback(
        async (calls: any, isDebit?: boolean, debitAmount = 0) => {
            try {
                const { paymaster } = await getPaymaster(
                    currentChain.contracts.token as `0x${string}`,
                    calls
                );
                const maxCostAfterBuffer = BigInt(10 ** 9);

                //const projectedMaxFees = formatUnits(maxCostInToken, 6);

                if (isDebit) {
                    if (
                        debitAmount + Number(2) >
                        (balance?.formatted as number)
                    ) {
                        return {
                            isError: true,
                            title: "Not enough balance for gas fees",
                            description: `You must have minimum ${Number(
                                2
                            ).toFixed(2)} USD balance reserved for gas fees`,
                            hash: undefined,
                        };
                    }
                } else {
                    // if (balance && Number(2) > (balance?.formatted as number)) {
                    //     return {
                    //         isError: true,
                    //         title: "Not enough balance for gas fees",
                    //         description: `You must have minimum ${Number(
                    //             2
                    //         ).toFixed(2)}  USD balance reserved for gas fees`,
                    //         hash: undefined,
                    //     };
                    // }
                }

                const callsWithPaymaster = [
                    {
                        abi: erc20Abi,
                        functionName: "approve",
                        args: [paymaster, maxCostAfterBuffer],
                        to: currentChain.contracts.token,
                    },
                    ...calls,
                ];

                const txHash = await smartAccountClient?.sendTransaction({
                    // @ts-ignore
                    paymasterContext: {
                        token: currentChain.contracts.token,
                        mode: "ERC20",
                    },
                    //@ts-ignore
                    calls: callsWithPaymaster,
                });

                return { hash: txHash };
            } catch (err: any) {
                console.warn("Transaction error paymaster", { err });
                const shortMessage = err.shortMessage
                    ? err.shortMessage
                          .toLowerCase()
                          .includes("function reverted")
                        ? err.shortMessage +
                          "Please make sure you have enough USDC to cover gas cost"
                        : err.shortMessage
                    : null;
                return {
                    isError: true,
                    title: "Transaction Failed",
                    description:
                        shortMessage ||
                        err.message ||
                        "Something went wrong. Please try again. If the problem persists, check the logs and contact support.",
                    hash: undefined,
                };
            }
        },
        [
            smartAccountClient,
            currentChain.contracts.token,
            getPaymaster,
            balance,
        ]
    );

    return {
        smartAccountClient,
        smartAccountAddress: smartWallet?.address,
        pimlicoClient,
        prepareUserOperation,
        sendSmartWalletOrder,
        logoutSmartAccount,
        signFTUEMessage,
        embeddedWalletAddress: embeddedWallet?.address,
    };
};

export interface IPrivySmartWalletProvider {
    children: JSX.Element | JSX.Element[];
}

const PrivySmartWalletProvider = ({ children }: IPrivySmartWalletProvider) => {
    const value = usePrivySmartWallet();

    return (
        <PrivySmartWalletContext.Provider value={value}>
            {children}
        </PrivySmartWalletContext.Provider>
    );
};

const usePrivySmartWalletContext = () => useContext(PrivySmartWalletContext);

export {
    PrivySmartWalletContext,
    usePrivySmartWalletContext,
    PrivySmartWalletProvider,
};

const REGISTER_USER = gql`
    mutation registerUserFE($address: String!) {
        registerUserFE(address: $address) {
            id
            address
            ftueDone
            username
            referralCode
            signedMessageTimestamp
            accessCode
        }
    }
`;
