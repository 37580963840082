import { AppProvider, useAppContext } from "@contexts/app";
import { Web3Provider } from "@contexts/web3";
import { TutorialProvider } from "@organisms/Tutorial/context";
import { ReactNode } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Platform } from "./components/Platform";
import { Toasts } from "./components/Toasts";
import { NotificationProvider } from "./notification";
import { TrackingProvider } from "./tracking";
import { PrivySmartWalletProvider } from "./privySmartWallet";

export const Store = ({
    children,
    pageProps,
}: {
    children: JSX.Element | JSX.Element[];
    pageProps: any;
}) => {
    return (
        <Web3Provider>
            <PrivySmartWalletProvider>
                <AppProvider pageProps={pageProps}>
                    <AllProviders>{children}</AllProviders>
                </AppProvider>
            </PrivySmartWalletProvider>
        </Web3Provider>
    );
};

export const SimpleStore = ({
    children,
    pageProps,
}: {
    children: JSX.Element | JSX.Element[];
    pageProps: any;
}) => {
    return (
        <Web3Provider>
            <AppProvider pageProps={pageProps}>
                <NotificationProvider>
                    <Toasts />
                    <Platform>{children}</Platform>
                </NotificationProvider>
            </AppProvider>
        </Web3Provider>
    );
};

const AllProviders = ({ children }: { children: ReactNode }) => {
    return (
        <NotificationProvider>
            <SafeAreaProvider>
                <Toasts />
                <Platform>
                    <TrackingProvider>
                        <TutorialProvider>
                            {/* <Suspense
                                fallback={
                                    <Box
                                        style={{
                                            background: theme.color.black,
                                            position: "fixed",
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            top: 0,
                                            justifyContent: "center",
                                            alignItems: "center",
                                            zIndex: 99,
                                        }}
                                    >
                                        <InfinityLoader />
                                    </Box>
                                }
                            > */}
                            {children}

                            {/* </Suspense> */}
                        </TutorialProvider>
                    </TrackingProvider>
                </Platform>
            </SafeAreaProvider>
        </NotificationProvider>
    );
};

export default Store;
